import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../actions/AppActions';
import { URL } from '../../variables';
import { BsFillEyeFill } from "react-icons/bs";
import DefaultButton from '../ReusableComponents/DefaultButton';
import { RiArrowGoBackLine } from 'react-icons/ri';
import { Button } from "react-bootstrap";
import { FaListAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { MdModeEdit, MdDelete, MdEdit } from 'react-icons/md';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SweetAlert from 'react-bootstrap-sweetalert';
import Skeleton from '@material-ui/lab/Skeleton';
import TabPanel from '../ReusableComponents/TabPanel';
import { Tab, Tabs } from '@material-ui/core';
import TextReadMore from '../ReusableComponents/TextReadMore';

class ShowModulo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            name: '',
            description: '',
            showDeleteConfirmation: false,
            errorMsg: '',
            contentToDelete: null,
            conteudos: [],
            loading: true,
            activeTab:0
        };
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.getModule(id);
        localStorage.setItem('idContent', id);
    }

    async getModule(id) {
        this.setState({ loading: true });
        try {
            const response = await fetch(`${URL}api/modulo/get_module/${id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${this.props.token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Erro ao buscar o módulo');
            }

            const data = await response.json();
            const { name, description, conteudos } = data.module;

            this.setState({
                name: name,
                description: description,
                conteudos: conteudos,
                loading: false
            });

            if (data.errors) {
                let errors = Object.values(data.errors);
                let errorMsg = errors.join('\n');
                this.setState({ errorMsg: errorMsg });
            }
        } catch (error) {
            console.error(error.message || 'Erro ao buscar o módulo');
            this.setState({ errorMsg: error.message || 'Erro ao buscar o módulo', loading: false });
        }
    }

    async saveOrder(conteudos) {
        const { id } = this.props.match.params;
        try {
            const response = await fetch(`${URL}api/modulo/edit_module/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.props.token}`,
                },
                body: JSON.stringify({
                    name: this.state.name,
                    description: this.state.description,
                    conteudo_ids: conteudos.map(conteudo => conteudo.id)
                }),
            });

            if (!response.ok) {
                throw new Error('Erro ao salvar a nova ordem');
            }

            const data = await response.json();
            if (data.errors) {
                let errors = Object.values(data.errors);
                let errorMsg = errors.join('\n');
                this.setState({ errorMsg: errorMsg });
            }
        } catch (error) {
            console.error(error.message || 'Erro ao salvar a nova ordem');
            this.setState({ errorMsg: error.message || 'Erro ao salvar a nova ordem' });
        }
    }

    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const conteudos = [...this.state.conteudos];
        const [reorderedItem] = conteudos.splice(result.source.index, 1);
        conteudos.splice(result.destination.index, 0, reorderedItem);

        this.setState({ conteudos: conteudos }, () => {
            this.saveOrder(conteudos);
        });
    }

    handleDeleteContent = async (conteudo) => {
        const { id } = this.props.match.params;

        try {
            const response = await fetch(`${URL}api/modulo/remove_content_from_module/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.props.token}`,
                },
                body: JSON.stringify({
                    conteudo_id: conteudo
                }),
            });

            if (!response.ok) {
                throw new Error('Erro ao desvincular conteúdo');
            }

            const data = await response.json();
            if (data.errors) {
                let errors = Object.values(data.errors);
                let errorMsg = errors.join('\n');
                this.setState({ errorMsg: errorMsg });
            } else {
                this.setState(prevState => ({
                    conteudos: prevState.conteudos.filter(item => item.id !== conteudo.id),
                    showDeleteConfirmation: false
                }));
            }
        } catch (error) {
            console.error(error.message || 'Erro ao desvincular conteúdo');
            this.setState({ errorMsg: error.message || 'Erro ao desvincular conteúdo' });
        } finally {
            this.getModule(id);
        }
    };





    handleDeleteConfirmation(conteudo) {
        this.setState({ contentToDelete: conteudo, showDeleteConfirmation: true });
    }

    renderContentSkeleton() {
        return (
            <div>
                <div style={{ float: 'right' }}>
                    <Skeleton variant="text" width={100} height={40} />
                </div>
                <div className="d-flex flex-column">
                    <Skeleton variant="text" width={200} height={40} />
                    <Skeleton variant="text" width={300} height={20} />
                </div>

                <hr />
                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>
                    <Skeleton variant="text" width={300} height={30} />
                </h4>
                <p style={{ fontSize: '1rem', color: 'black' }}>
                    <Skeleton variant="text" width={400} height={20} />
                </p>
                <div className="d-flex" style={{ justifyContent: 'flex-end' }}>
                    <Skeleton variant="rect" width={150} height={40} style={{ marginRight: '10px' }} />
                    <Skeleton variant="rect" width={150} height={40} />
                </div>

                <br /><br />
                <div className="row">
                    <div className="col-12">
                        <Skeleton variant="rect" width="100%" height={400} />
                    </div>
                </div>
            </div>
        );
    }

    renderContent() {
        const { name, description, conteudos, contentToDelete, errorMsg, showDeleteConfirmation
        } = this.state;
        const { id } = this.props.match.params;

        return (
            <div>
                <div style={{ float: 'right' }}>
                    <DefaultButton
                        bg="secondary"
                        text="Voltar"
                        icon={<RiArrowGoBackLine />}
                        to={`/modulos`}
                    />
                </div>
                <div className="flex jcsb">
                                <h4 className="header">{name}</h4>

                            </div>
                            <div className="w100 margin-bottom">

                                <TextReadMore title="Descrição: " element="div" limit={1000} style={{ width: "100%", marginBottom: 10, whiteSpace: 'pre' }}>
                                    {description}
                                </TextReadMore>
                            </div>

                <Tabs
                    value={this.state.activeTab}
                    onChange={(_, value) => {
                        this.setState({ activeTab: value });
                        this.props.history.push({ search: `?tab=${value}` });
                    }}
                    style={{ backgroundColor: "#ececf1" }}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={"scrollable"}
                    scrollButtons={"on"}

                >
                    <Tab label="Conteúdos" />

                </Tabs>
                <TabPanel value={this.state.activeTab} index={0}>



                    <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados todos os conteúdos vinculados com o módulo {name}</p>
                    <div className="d-flex" style={{ justifyContent: 'flex-end' }}>

                        <Link to={'/modulo/edit/'+id} style={{ textDecoration: "none" }}>
                            <Button variant="success" className="flex align-center jcc">
                                <MdEdit style={{ marginRight: 10 }} />
                                <p style={{ margin: 0 }}>Editar Módulo/Conteúdos</p>
                            </Button>
                        </Link>
                    </div>

                    <br />
                    <div className="row">
                        <div className="col-12">
                            <DragDropContext onDragEnd={this.onDragEnd}>
                                <Droppable droppableId="conteudos">
                                    {(provided) => (
                                        <table
                                            id="tech-conteudos"
                                            className="table table-striped"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            <thead>
                                                <tr>
                                                    <th>Nome</th>
                                                    <th>Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {conteudos.map((conteudo, index) => (
                                                    <Draggable key={conteudo.id.toString()} draggableId={conteudo.id.toString()} index={index}>
                                                        {(provided) => (
                                                            <tr
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <td>{conteudo.name}</td>
                                                                <td>
                                                                    <Link to={`/conteudo/show/${conteudo.id}`} onClick={(e) => e.stopPropagation()}>
                                                                        <span className="inline-flex align-center jcc bg-warning icon" style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px" }} title="Gerenciar">
                                                                            <BsFillEyeFill size={17} color="white" />
                                                                        </span>
                                                                    </Link>
                                                                    
                                                                    <span
                                                                        onClick={() => this.setState({ showDeleteConfirmation: true, contentToDelete: conteudo.id })}
                                                                        className="bg-danger icon inline-flex justify-content-center align-items-center"
                                                                        style={{ width: "2em", height: "2em", borderRadius: "7px", cursor: 'pointer' }}
                                                                        title="Deletar treinamento"
                                                                    >
                                                                        <MdDelete size={17} color="white" />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </tbody>
                                        </table>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                    {conteudos.length === 0 && (
                                        <div className="row w-100 mt-3">
                                            <div className="col-12">
                                                <p style={{ textAlign: 'center' }}>Nenhum conteúdo cadastrado</p>
                                            </div>
                                        </div>
                                    )}
                </TabPanel>

                <SweetAlert
                    warning
                    title={"Atenção"}
                    show={showDeleteConfirmation}
                    onConfirm={() => {
                        this.handleDeleteContent(contentToDelete);
                        this.setState({ showDeleteConfirmation: false, loading_search: true });
                    }}
                    onCancel={() => this.setState({ showDeleteConfirmation: false })}
                    confirmBtnText="Sim, desejo desvincular "
                    cancelBtnText="Cancelar"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    {errorMsg ? errorMsg : "Ao desvincular este treinamento, ele não estará mais disponível no seu módulo"}
                </SweetAlert>
            </div>
        );
    }

    render() {
        const { loading } = this.state;

        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">
                            {loading ? (
                                this.renderContentSkeleton()
                            ) : (
                                this.renderContent()
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.AppReducer.user,
    token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(ShowModulo);