// Assets Imports
import "./assets/ChatBotQuestao.css";
import { URL } from "../../variables";

// Library Imports
import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Card, Form, Modal } from "react-bootstrap";
import {
  FaPaperclip,
  FaTrash,
  FaRedo,
  FaArrowLeft,
  FaEdit,
  FaSave,
} from "react-icons/fa";
import CircularProgress from "@mui/material/CircularProgress";
import { connect, useDispatch } from "react-redux";

// Component Imports
import ContextForm from "./components/ContextForm";
import ValidationModal from "./components/ValidationModal";

// Service Imports
import { logout,QuestionIA } from "../../actions/AppActions";
import { MdCheck, MdEdit } from "react-icons/md";
import moment from "moment";
import { useHistory } from "react-router-dom";
const GENERATING_MESSAGES = [
  "Gerando resposta, por favor aguarde...",
  "Estou pensando na melhor resposta...",
  "Processando sua pergunta, aguarde um momento...",
  "Calculando a resposta perfeita para você...",
];
const ChatBotQuestao = ({ token }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading_questions, setLoadingQuestions] = useState(false);

  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const { id } = useParams();
  const history = useHistory();

  const [context, setContext] = useState({
    tema: "",
    disciplina_id: id,
    disciplina:'',
    tipoQuestao: "",
    nivelDificuldade: "",
    nivelEscolaridade: "",
    topicosEspecificos: "",
    quantidadeAlternativas: 0,
    contexto: "",
  });
  const [isEditingContext, setIsEditingContext] = useState(true);
  const [editingMessageIndex, setEditingMessageIndex] = useState(null);
  const [editedMessage, setEditedMessage] = useState("");
  const [questionId, setQuestionId] = useState(null); // Novo estado para armazenar o ID da questão
  const chatBodyRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [messageIndexToValidate, setMessageIndexToValidate] = useState(null);
  const [categoria_id, setCategoriaId] = useState(id);

  const dispatch = useDispatch();


  useEffect(() => {
    if(categoria_id!=null){
      fetchQuestions(categoria_id)
    }
    return () => {
     
    };
  }, [categoria_id]);
 

  useEffect(() => {
    // fetchQuestions()
    return () => {
     
    };
  }, []);

  useEffect(() => {
    if (!loading) return;
    const interval = setInterval(() => {
      setLoadingMessageIndex(
        (prevIndex) => (prevIndex + 1) % GENERATING_MESSAGES.length
      );
    }, 4000);
    return () => clearInterval(interval);
  }, [loading]);

  useEffect(() => {
    // const savedContext = localStorage.getItem("context");
    // console.log('conntext', savedContext)
    // if (savedContext) {
      // setContext(JSON.parse(savedContext));
      // setIsEditingContext(false);
    // }
  }, []);

  const handleContextSubmit = (contextData) => {
    if (isEditingContext) {
      setContext(contextData);
      // localStorage.setItem("context", JSON.stringify(contextData));
      setIsEditingContext(false);
    }
  };

  const handleEditContext = () => {
    setIsEditingContext(true);
  };

  const handleVincularMessage = async () => {
    dispatch(QuestionIA(messageIndexToValidate))
    history.push(`/questoes/register/${messageIndexToValidate.categoria_id}`);

    
  };

  const fetchQuestions = async (categoria_id) => {
    try {
      setLoadingQuestions(true)
      const response = await fetch(`${URL}api/faculdade/listar_questoes?categoria_id=${categoria_id}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Accept": "application/json",
        },
        body:JSON.stringify({})
      });
      const resp = await response.json();

      if (resp.message == "Unauthenticated.") {
        localStorage.removeItem('token'); localStorage.removeItem('user');
        // this.setState({redirect:true,path:'/'});
        dispatch(logout())
        return;
      }
      if (resp.errors != null || resp.error != null) {
        let errors = Object.values(resp.errors);
        let erro = '';
        for (let i = 0; i < errors.length; i++) {
          if (i != errors.length - 1)
            erro += errors[i] + '\n';
          else
            erro += errors[i];
        }
        console.log(erro);

        //   setTimeout(()=>{
        //     this.get_processo();
        //   });
      }
      else{
        setMessages(resp.questoes); // Store the question ID

      }
      setLoadingQuestions(false)

    } catch (error) {
      console.error("Erro ao salvar a questão:", error);
    }
  };

  const saveQuestion = async (questionData) => {
    setLoading(true);

    try {
      console.log(questionData)
      const response = await fetch(`${URL}api/faculdade/criar_questao`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Accept": "application/json",
        },
        body: JSON.stringify(questionData),
      });
      const resp = await response.json();

      if (resp.message == "Unauthenticated.") {
        localStorage.removeItem('token'); localStorage.removeItem('user');
        // this.setState({redirect:true,path:'/'});
        dispatch(logout())
        return;
      }
      if (resp.errors != null || resp.error != null) {
        let errors = Object.values(resp.errors);
        let erro = '';
        for (let i = 0; i < errors.length; i++) {
          if (i != errors.length - 1)
            erro += errors[i] + '\n';
          else
            erro += errors[i];
        }
        console.log(erro);

        //   setTimeout(()=>{
        //     this.get_processo();
        //   });
      }
      else{
        console.log(resp)
        setMessages((prevMessages) => [
          resp.questao,
          ...prevMessages,
        ]);
      }
    setLoading(false);


    } catch (error) {
    setLoading(false);

      console.error("Erro ao salvar a questão:", error);
    }
  };

  const handleSend = useCallback(() => {
    if (!context.tema) return;

    saveQuestion(context);
    return;

  }, [context, token]);

  

  const handleEditMessage = (index) => {
    setEditingMessageIndex(index);
    setEditedMessage(messages[index].text);
  };

  const handleSaveEdit = (index) => {
    const updatedMessages = [...messages];
    updatedMessages[index].text = editedMessage;
    setMessages(updatedMessages);
    setEditingMessageIndex(null);
    setEditedMessage("");
  };

  const handleCancelEdit = () => {
    setEditingMessageIndex(null);
    setEditedMessage("");
  };

  useEffect(() => {
    if (chatBodyRef.current)
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
  }, [messages]);

  const handleShowModal = (index) => {
    setMessageIndexToValidate(index);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setMessageIndexToValidate(null);
  };

  return (
    <div className="chat-container">
     

      <div className="main-content">
        <Card className="context-form">
          <Card.Body>
            <div className="context-section">

              {isEditingContext ? (
                <ContextForm setCategoriaId={(e)=>{
                  setCategoriaId(e)
                }} onSubmit={handleContextSubmit} id={id} initialData={context} />
              ) : (
                <>
                  <Card.Title style={{ fontSize: '1.5rem', color: 'black', fontWeight: 'bold' }}>Contexto da Questão</Card.Title>
                  <Card.Text>
                    <p style={{ marginBottom: 5 }}><b>Disciplina:</b> {context.disciplina}</p>
                    <p style={{ marginBottom: 5 }}><b>Tipo de Questão:</b> {context.tipoQuestao}</p>
                    <p style={{ marginBottom: 5 }}><b>Quantidade de Alternativas:</b> {context.quantidadeAlternativas || "Não especificado"}</p>
                    <p style={{ marginBottom: 5 }}><b>Nível de Escolaridade:</b> {context.nivelEscolaridade}</p>
                    <p style={{ marginBottom: 5 }}><b>Nível de Dificuldade:</b> {context.nivelDificuldade}</p>
                    <p style={{ marginBottom: 5 }}><b>Tema Principal:</b> {context.tema}</p>
                    <p style={{ marginBottom: 5 }}><b>Contexto:</b> {context.contexto || "Não especificado"}</p>
                  </Card.Text>


                  {loading && (
              <div className="loading-indicator d-flex justify-content-center">
                <CircularProgress size={24} />
                <span className="ms-3 text-muted">
                  {GENERATING_MESSAGES[loadingMessageIndex]}
                </span>
              </div>
            )}
                 
                 {!loading && <footer className="chat-footer d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                  
                  {
                    <Button onClick={handleEditContext} variant="outline-primary" className="me-2">
                      <FaArrowLeft /> Editar/Criar Novo Contexto
                    </Button>
                  }
                  {/* <Button variant="outline-primary ms-2" onClick={handleEditContext}>
                    <MdEdit/> Editar/Criar Novo Contexto
                  </Button> */}
                  </div>
                  
                  <Button
                    variant="success"
                    onClick={handleSend}
                    disabled={isEditingContext}
                  >
                    Gerar Questão <MdCheck/>
                  </Button>
            </footer>}

                </>
              )}
            </div>


          </Card.Body>
        </Card>

        <div className="chat-section mt-4">
          <div className="chat-body" ref={chatBodyRef}>
          <Card  className="message mb-4">
          <Card.Body>
          <Card.Title style={{ fontSize: '1.5rem', color: 'black', fontWeight: 'bold' }}>Questões Geradas Pendentes de Validação</Card.Title>
          <p style={{ fontSize: '1rem', color: 'black' }}>Altere a disciplina do contexto para ver questão pendentes de outra disciplina</p>
            {loading_questions==false ? <>
            {messages.length==0 && <><hr/><p style={{marginBottom:0}}>Nenhuma questão encontrada</p></>}
           
            {messages.map((msg, index) => (
                <div key={index}>
                  <hr/>
                  {editingMessageIndex === index ? (
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={editedMessage}
                      onChange={(e) => setEditedMessage(e.target.value)}
                    />
                  ) : (
                    <>
                      {(
                        <div >
                          <div style={{ marginBottom: "10px" }}>
                            <strong>Tipo: </strong><span>{msg.tipo_questao}</span>
                          </div>
                          <div style={{ marginBottom: "10px" }}>
                            <strong>Disciplina: </strong><span>{msg.categoria.name}</span>
                          </div>
                          <div style={{ marginBottom: "10px" }}>
                            <strong>Gerada em: </strong><span>{moment(msg.created_at).format('DD/MM/YYYY HH:mm')}</span>
                          </div>
                          <div
                            className="solucao"
                            style={{ marginBottom: "10px" }}
                          >
                            <strong>{'Palavras chaves'}:</strong>{" "}
                            <span>
                              {msg.palavras_chaves}
                            </span>
                          </div>
                          <div
                            className="enunciado"
                            style={{ marginBottom: "10px" }}
                          >
                            <strong>Enunciado:</strong>{" "}
                            <span>
                              {msg.enunciado}
                            </span>
                          </div>
                          
                          
                          {msg.tipo_questao == "Questão objetiva" && <div
                            className="alternativas"
                            style={{ marginBottom: "10px" }}
                          >
                            <strong>Alternativas:</strong>
                            <div style={{ marginTop: "5px" }}>
                              {msg?.alternativas?.map((alt, index) => (
                                <div
                                  key={index}
                                  style={{ marginBottom: "5px" }}
                                >
                                  {alt}
                                </div>
                              ))}
                            </div>
                          </div>}

                          <div
                            className="solucao"
                            style={{ marginBottom: "10px" }}
                          >
                            <strong>{msg.tipo_questao == "Questão objetiva" ? 'Solução' : 'Resposta'}:</strong>{" "}
                            <span>
                              {msg.resposta_solucao}
                            </span>
                          </div>


                        </div>
                      )}
                    </>
                  )}
                  {editingMessageIndex === index ? (
                    <>
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => handleSaveEdit(index)}
                      >
                        <FaSave /> Salvar
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        className="ms-2"
                        onClick={handleCancelEdit}
                      >
                        Cancelar
                      </Button>
                    </>
                  ) : (
                    <>
                      {/* <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleEditMessage(index)}
                      >
                        <FaEdit /> Editar
                      </Button> */}
                      {(
                        <Button
                          variant="info"
                          size="sm"
                          className="ms-0"
                          onClick={() => handleShowModal(msg)}
                        >
                          Validar Questão <FaPaperclip />
                        </Button>
                      )}
                    </>
                  )}
              </div>
            ))}
            </> : <div className="d-flex justify-content-center">
            <CircularProgress/>
            </div>}

 </Card.Body>

</Card>
            
          </div>

          {/* <footer className="chat-footer d-flex justify-content-between align-items-center">
            <div>
              <Button
                variant="outline-danger"
                className="me-2"
                onClick={handleClearMessages}
              >
                <FaTrash /> Limpar
              </Button>
            </div>


          </footer> */}

        </div>

      </div>

      <ValidationModal
        show={showModal}
        onClose={handleCloseModal}
        onValidate={handleVincularMessage}
        id={id}
        isEditingContext={isEditingContext}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.AppReducer.token,
});

export default connect(mapStateToProps)(ChatBotQuestao);
