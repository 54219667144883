// Library Imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { RiArrowGoBackLine, RiErrorWarningLine } from "react-icons/ri";
import { Modal, Button, Dropdown } from "react-bootstrap";

// Components Imports
import DefaultButton from "../ReusableComponents/DefaultButton";
import Search from "../ReusableComponents/Search";

// Utils Imports
import { get, post } from "../../Services/api";
import { MdSave } from "react-icons/md";

class VincularInstituicao extends Component {
  constructor(props){
    super(props);

    this.state = {
      allTrainings: [],
      loading: false,
      pagination: {
        current_page: 1,
        last_page: 1,
        total: 0,
        per_page: 30,
      },
      isMounted: false,
      errorMsg: "",
      search: "",
      loading_search: false,
      selectedTrainings: new Set(),
      showModal: false,
      filter: "todos",
      loadingSave:false,
      msgErro:''
    };
  }
  

  componentDidMount() {
    this.setState({ isMounted: true });
    this.loadTrainings();
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  loadTrainings = async () => {
    await this.getAllTrainings();
  };

  getAllTrainings = async (
    page = this.state.pagination.current_page,
    search = this.state.search,
    busca = true
  ) => {
    const { token } = this.props;
    const { loading_search, loading } = this.state;

    if (busca && !loading_search) {
      this.setState({ loading_search: true });
    } else if (!busca && !loading) {
      this.setState({ loading: true });
    }

    try {
      const data = await get(
        `api/treinamento/get_trainings?page=${page}&search=${search}&instituicao_id=${this.props.match.params.id}`,
        token
      );

      if (this.state.isMounted) {
        this.setState({
          allTrainings: data.data || [],
          loading: false,
          loading_search: false,
          pagination: {
            current_page: data.pagination.current_page,
            last_page: data.pagination.last_page,
            total: data.pagination.total,
            per_page: data.pagination.per_page,
          },
        });
      }
    } catch (error) {
      console.error(error.message || "Erro ao buscar treinamentos");
    } finally {
      if (this.state.isMounted) {
        this.setState({ loading: false });
      }
    }
  };

  

  handleConfirmarVinculo = async () => {
    const { selectedTrainings } = this.state;
    try {
      await this.vincularTreinamento(Array.from(selectedTrainings));
    } catch (error) {
      console.error(error.message || "Erro ao executar as funções");
      this.setState({
        msgErro: error.message || "Erro ao executar as funções",
        showModal: true,
      });
    }
  };

  vincularTreinamento = async (trainingId) => {
    this.setState({loadingSave:true,msgErro:''})
    const faculdadeId = this.props.match.params.id;
    console.log(trainingId)
    try {
      let resp = await post(
        `api/faculdade/vincular_treinamento`,
        {
          faculdade_id: faculdadeId,
          ids: trainingId,
        },
        this.props.token
      );

      if(resp.status==false){
        this.setState({msgErro:resp.message,loadingSave:false})
      }
      else{
        this.props.history.push(`/instituicao/show/${faculdadeId}?tab=6`);
      }

      // this.setState({ errorMsg: "", showModal: false });
    } catch (error) {
      console.error(error.message || "Erro ao vincular treinamento");
      this.setState({
        msgErro: error.message || "Erro ao vincular treinamento",
        showModal: true,
      });
    }
  };

  handlePageChange = (event, value) => {
    this.setState({
      pagination: { ...this.state.pagination, current_page: value },
    });
    this.loadTrainings();
  };

  handleSearchChange = (value) => {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.setState({
        search: value,
        pagination: { ...this.state.pagination, current_page: 1 },
        loading_search: true,
      },()=>{
        this.loadTrainings();
      });
    }, 500);
  };

  handleTrainingSelection = (trainingId) => {
    this.setState((prevState) => {
      const selectedTrainings = new Set(prevState.selectedTrainings);
      if (selectedTrainings.has(trainingId)) {
        selectedTrainings.delete(trainingId);
      } else {
        selectedTrainings.add(trainingId);
      }
      return { selectedTrainings };
    });
  };

  
  

  closeModal = () => {
    this.setState({ showModal: false });
  };

  formatarData = (dataString) => {
    const data = new Date(dataString);
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    const horas = String(data.getHours()).padStart(2, '0');
    const minutos = String(data.getMinutes()).padStart(2, '0');
    const segundos = String(data.getSeconds()).padStart(2, '0');

    return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
}

  render() {
    const {
      loading,
      loading_search,
      allTrainings,
      pagination,
      errorMsg,
      showModal,
      selectedTrainings,
    } = this.state;
    const faculdadeId = this.props.match.params.id;

   

    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body" id="card">
           
              <h4
                className="mt-0 header-title"
                style={{ fontSize: "1.5rem", color: "black" }}
              >
                Vincular Treinamento
              </h4>
              <p style={{ fontSize: "1rem", color: "black" }}>
                Aqui estão listados todos os treinamentos do seu sistema
              </p>
              <br />
              {this.state.msgErro && (
              <div style={{ display: "flex", marginBottom: 15, width: "100%" }}>
                <div
                  className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                  role="alert"
                >
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() =>
                    {
                      this.setState({msgErro:''})
                    }
                    }
                  ></button>
                  <p style={{ marginBottom: 0 }}>{this.state.msgErro}</p>
                </div>
              </div>
            )}
              {!loading && <div className="row">
                <div className="col-12">
                  <div className="flex jcsb flex-wrap mb-4">
                    <Search
                      placeholder="Pesquisar pelo nome do treinamento"
                      ValueChange={this.handleSearchChange}
                    />

                    

                    
                  </div>

                  <div className="table-rep-plugin">
                     {
                      <div className="table-responsive mb-0">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Nome</th>
                              <th>Número de Módulos</th>
                                                            <th>Data de criação</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!loading_search &&
                              allTrainings.map((training, index) => (
                                <tr key={index}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={selectedTrainings.has(
                                        training.id
                                      )}
                                      onChange={() =>
                                        this.handleTrainingSelection(
                                          training.id
                                        )
                                      }
                                    />
                                  </td>
                                  <td>{training.nome}</td>
                                  <td>{training.numero_modulos}</td>

                                                                <td>{this.formatarData(training.created_at)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    }
                    {loading_search && (
                      <div className="row" style={{ margin: 0 }}>
                        <div className="col-12 d-flex justify-content-center align-items-center">
                          <CircularProgress />
                        </div>
                      </div>
                    )}
                    {!loading_search && allTrainings.length === 0 && (
                      <div className="row w-100">
                        <div className="col-12">
                          <p style={{ textAlign: "center" }}>
                            Nenhum treinamento encontrado com este termo
                          </p>
                        </div>
                      </div>
                    )}

                    <Pagination
                      style={{ display: "flex", justifyContent: "flex-end" }}
                      count={pagination.last_page}
                      page={pagination.current_page}
                      onChange={this.handlePageChange}
                    />
                  </div>
                 

                  <div className="w-100 d-flex justify-content-between mt-3">
                  <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine />}
                    to={`/instituicao/show/${faculdadeId}`}
                    search="?tab=6"
                  />

                  <DefaultButton
                    type="button"
                    onClick={(e)=>{
                      this.handleConfirmarVinculo(e)
                    }}
                    bg="confirm"
                    text="Vincular Selecionados"
                    icon={<MdSave />}
                    disabled={selectedTrainings.size === 0}
                    loadingtext="Vinculando..."
                    loading={this.state.loadingSave}
                  />
                </div>
                </div>
              </div>}
              {loading && <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "200px" }}
        >
          <CircularProgress />
        </div>}
            </div>
          </div>
        </div>
        <Modal show={showModal} onHide={this.closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <RiErrorWarningLine
                style={{
                  marginRight: "8px",
                  marginBottom: "5px",
                  color: "red",
                }}
              />
              Erro
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{errorMsg}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.AppReducer.token,
  faculdade_id: state.AppReducer.faculdade_id,
});

export default connect(mapStateToProps)(withRouter(VincularInstituicao));
