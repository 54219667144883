import React, { useState } from 'react';
import Attention from '../ReusableComponents/Attention';
import { URL } from '../../variables';
import { connect, useDispatch } from 'react-redux';
import { logout } from '../../actions/AppActions';
import { RiArrowGoBackLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import DefaultButton from '../ReusableComponents/DefaultButton';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner, ProgressBar } from "react-bootstrap";
import axios from 'axios';

const CriarConteudo = ({ token }) => {
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [file, setFile] = useState(null);
    const [thumbnail, setThumbnail] = useState(null);
    const [loadingSave, setLoadingSave] = useState(false);
    const [msgErro, setMsgErro] = useState('');
    const [filePreview, setFilePreview] = useState(null);
    const [thumbnailPreview, setThumbnailPreview] = useState(null);
    const [hasQuestions, setHasQuestions] = useState(false);
    const history = useHistory();
  const [progress, SetProgress] = useState(0);
    const dispatch = useDispatch();

    const pdfUpload = React.useRef();
    const videoUpload = React.useRef();
    const imageUpload = React.useRef();

    const salvar = (e) => {
        e.preventDefault();
        setLoadingSave(true);
        setMsgErro('');

        const formData = new FormData();
        formData.append('name', name);
        formData.append('type', type);
        formData.append('file', file);
        formData.append('has_questions', hasQuestions ? 'true' : 'false');

        if (thumbnail !== null) {
            formData.append('thumbnail', thumbnail);
        }

        return axios.post(`${URL}api/conteudo/store_content`, formData, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
            },
            onUploadProgress: (e) => {
              console.log(e);
              SetProgress((e.loaded / e.total) * 100);
            }
          })
            .then((response) => {
              // if (!response.ok) {
              //     throw new Error('Erro ao cadastrar conteúdo');
              // }
              return response.data;
            })
            .then(resp => {
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    dispatch(logout());
                    return;
                }
              if (resp.errors != null || resp.error != null) {
                let errors = Object.values(resp.errors);
                let erro = '';
                for (let i = 0; i < errors.length; i++) {
                  if (i != errors.length - 1)
                    erro += errors[i] + '\n';
                  else
                    erro += errors[i];
                }
                console.log(erro);
                setLoadingSave(false);
                setMsgErro(erro)
              }
              else {
                history.push("/conteudos");
              }
      
              return resp;
            })
            .catch((err) => {
              let resp = err.response.data;
              if (resp.message == "Unauthenticated.") {
                localStorage.removeItem('token'); localStorage.removeItem('user');
                dispatch(logout());
                return;
            }
              if (resp.errors != null || resp.error != null) {
                let errors = Object.values(resp.errors);
                let erro = '';
                for (let i = 0; i < errors.length; i++) {
                  if (i != errors.length - 1)
                    erro += errors[i] + '\n';
                  else
                    erro += errors[i];
                }
                console.log(erro);
                setMsgErro(erro)
              }
              else {
                setMsgErro(
                  "Erro ao pegar resposta do servidor. Você está conectado à internet?"
                );
              }
              console.log(err);
              setLoadingSave(false);
      
            });
       
    };





    const handleNomeChange = (e) => {
        setName(e.target.value);
    };
    const handleTypeChange = (e) => {
        setType(e.target.value);
        setFilePreview(null);
        setHasQuestions(false);
    };


    const handlePdfFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFilePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
        setFile(event.target.files[0]);
    };

    const handleVideoFileChange = (event) => {
        setFile(event.target.files[0]);
        setFilePreview(window.URL.createObjectURL(event.target.files[0]))
        setHasQuestions(false);
    };

    const handleCapaImageFileChange = (event) => {
        const thumbnail = event.target.files[0];
        if (thumbnail) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setThumbnailPreview(reader.result);
            };
            reader.readAsDataURL(thumbnail);
        }
        setThumbnail(event.target.files[0]);
    };

    const removeFilePDF = (e) => {
        e.preventDefault();
        setFilePreview(null);
        setFile(null);

        if (pdfUpload.current) {
            pdfUpload.current.value = "";
        }
    }
    const removeFileAllVideo = (e) => {
        e.preventDefault();
        setFilePreview(null);
        setFile(null);
        setThumbnailPreview(null);
        setThumbnail(null);

        if (videoUpload.current || imageUpload.current) {
            videoUpload.current.value = "";
            imageUpload.current.value = "";
        }
    }
    const removeFileVideo = (e) => {
        e.preventDefault();
        setFilePreview(null);
        setFile(null);

        if (videoUpload.current) {
            videoUpload.current.value = "";
        }
    }
    const removeFileImage = (e) => {
        e.preventDefault();
        setFilePreview(null);
        if (imageUpload.current) {
            imageUpload.current.value = "";
        }
    }



    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body card-body-custom" id="card">
                        <h4 className="mt-0 header-title header-title-custom">Cadastrar Conteúdo</h4>
                        <p className="paragraph-custom">Cadastre os conteúdos do seu sistema</p>
                        {msgErro && (

                            <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                    <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setMsgErro('')}></button>
                                    <p style={{ marginBottom: 0 }}>{msgErro}</p>
                                </div>
                            </div>
                        )}

                        <form onSubmit={salvar} className="position-relative">
                            <div className="form-group mb-3">
                            <Form.Label>Nome <Attention /></Form.Label>
                            <input
                                    type="text"
                                    className="form-control input-custom"
                                    id="nome"
                                    placeholder="Nome do conteúdo"
                                    value={name}
                                    onChange={handleNomeChange}
                                    required
                                />
                            </div>
                            <div className="form-group mb-3">
                            <Form.Label>Tipo <Attention /></Form.Label>
                                <select
                                    className="form-control input-custom"
                                    id="tipo"
                                    value={type}
                                    onChange={handleTypeChange}
                                    required
                                >
                                    <option value="">Selecione qual será o tipo do arquivo</option>
                                    <option value="pdf">PDF</option>
                                    <option value="video">Vídeo</option>
                                </select>
                            </div>
                            {type === 'pdf' && (
                                <div className="form-group mb-3">
                            <Form.Label>Upload do PDF <Attention /></Form.Label>

                                    <input
                                        type="file"
                                        className="form-control input-custom"
                                        id="pdfUpload"
                                        accept=".pdf"
                                        onChange={handlePdfFileChange}
                                        ref={pdfUpload}
                                    />
                                    {filePreview && (
                                        <div className="mt-3">
                            <Form.Label>Pré-visualização do PDF</Form.Label>
                            <div>
                                            <iframe
                                                src={filePreview}
                                                style={{width:200,maxHeight:200,objectFit:'contain'}}
                                                frameBorder="0"
                                            />
                                        </div>

                                        </div>
                                    )}
                                    <button type="button" className="btn btn-danger btn-sm mt-3" onClick={e => removeFilePDF(e)}>Limpar arquivo</button>
                                </div>
                            )}
                            {type === 'video' && (
                                <div className="form-group mb-3">
                            <Form.Label>Upload do Vídeo (Menor do que 200 Mb) <Attention /></Form.Label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="file"
                                            className="form-control input-custom"
                                            id="videoUpload"
                                            accept=".mp4,.avi,.mov,.wmv,.mkv,.flv,.webm,.mpeg,.mpg,.3gp,.m4v"
                                            onChange={handleVideoFileChange}
                                            ref={videoUpload}
                                        />
                                        <button type="button" className="btn btn-danger" onClick={e => removeFileVideo(e)}>Limpar</button>
                                    </div>

                                    <Form.Label>Upload da Capa do Vídeo</Form.Label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="file"
                                            className="form-control input-custom"
                                            id="capaImage"
                                            accept=".jpg,.jpeg,.png,.bmp,.tiff,.tif,.svg,.webp"
                                            onChange={handleCapaImageFileChange}
                                            ref={imageUpload}
                                        />
                                        <button type="button" className="btn btn-danger" onClick={e => removeFileImage(e)}>Limpar</button>
                                    </div>


                                    {filePreview && (
                                                          <div className="mt-3">
                                                            <Form.Label>Pré-visualização do Vídeo </Form.Label>
                                                            <div>
                                    
                                                            <video
                                                              controls
                                                              src={filePreview}
                                                              className="img-thumbnail"
                                                              style={{width:200,height:200,objectFit:'contain'}}
                                                            />
                                                            </div>
                                    
                                                          </div>
                                                        )}
                                                        


                                    {thumbnailPreview && (
                                        <div className="form-group mb-3">
                                    <Form.Label>Pré-visualização da Capa</Form.Label>
                                            <div className="preview-container">
                                                <img style={{width:200,height:200,objectFit:'contain'}} src={thumbnailPreview} alt="Pré-visualização" className="img-preview" />
                                            </div>
                                        </div>
                                    )}
                                    <button type="button" className="btn btn-danger mt-3" onClick={e => removeFileAllVideo(e)}>Limpar todos os arquivos</button>
                                </div>
                            )}



                            <div className="form-check mb-3">
                                {/* <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="hasQuestions"
                                    checked={hasQuestions}
                                    onChange={() => setHasQuestions(!hasQuestions)}
                                />
                                <label className="form-check-label" htmlFor="hasQuestions">
                                    Há questões neste conteúdo?
                                </label> */}
                            </div> 


                            {loadingSave == false && <div className="d-flex justify-content-between mt-3">
                                <DefaultButton
                                    bg="secondary"
                                    text="Voltar"
                                    icon={<RiArrowGoBackLine />}
                                    to="/conteudos"
                                />
                                <DefaultButton
                                    type="submit"
                                    bg="confirm"
                                    text="Salvar conteúdo"
                                    disabled={loadingSave}
                                    loadingtext="Salvando conteúdo"
                                    loading={loadingSave}
                                />
                            </div>}
                            {loadingSave == true && <ProgressBar animated now={progress} label={parseInt(progress) + '%'} />}

                        </form>
                    </div>
                </div>
            </div>
        </div>
    );

};

const mapStateToProps = (state) => ({
    user: state.AppReducer.user,
    token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(CriarConteudo);