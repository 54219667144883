import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt, FaPlus, FaVideo } from "react-icons/fa";
import { MdDelete, MdFileDownload, MdInfo, MdModeEdit, MdVideoLibrary } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Zoom } from "@material-ui/core";
import moment from "moment";
import { saveAs } from 'file-saver';
import ModalCandidatosCsv from "./ModalCandidatosCsv";



const CandidatosVinculados = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_candidatos, setLoadingcandidatos] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');

    const [candidatos, setcandidatos] = useState([]);
    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });



    const [search, setSearch] = useState('');
    const { processo_id, processo_name, online } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [candidatos_id, setRedacaoId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();

    const [loading_zip, setLoadingZip] = useState(false);
    const [message_zip, setMessageZip] = useState('');

    const [loading_zip2, setLoadingZip2] = useState(false);
    const [message_zip2, setMessageZip2] = useState('');
    const timerRef = useRef(null);

    const user = useSelector(state => state.AppReducer.user);



    const Getcandidatos = async (page, search) => {

        let response = await Request.Get(`faculdade/get_candidatos_vinculados/${processo_id}?page=${page}&search=${search}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        console.log(search)
        console.log(search_oficial)
        console.log(page)


        if (response.pagination.current_page == page) {

            setcandidatos(response.candidatos);

            setPagination(response.pagination);
            setLoadingScreen(false);
            setLoadingcandidatos(false);


        }
    }

    const GetCandidatosZip = async () => {

        let response = await Request.Get(`faculdade/get_relatorios_candidatos/${processo_id}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        // else if(response['status']==undefined){
        //     setLoadingZip(false);

        //     saveAs(response,'provas_processo_'+processo_name+'.zip');
        // }
        else {
            if (response['status'] == true) {
                document.getElementById('download_link').href = response['path'];
                document.getElementById('download_link').click();
                setLoadingZip(false);
                setMessageZip('');

                return;
            }
            else if (response['status'] != 'Error') {
                setMessageZip(response['message']);
            }
            timerRef.current = setTimeout(() => {
                GetCandidatosZip();
            }, 20000);

        }
    }

    const GetVideoCandidato = async (user) => {

        let response = await Request.GetFile(`faculdade/get_video_candidato/${processo_id}/${user.id}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }

        saveAs(response, 'video_' + user.name + '.zip');

    }


    const GetRelatórioCandidato = async (user) => {

        let response = await Request.GetFile(`faculdade/get_relatorio_candidato/${processo_id}/${user.id}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }

        saveAs(response, 'relatorio_' + user.name + '.csv');

    }

    const DeleteRedacao = async (candidatos_id) => {

        let response = await Request.Delete(`faculdade/desvincular_candidato/${candidatos_id}/${processo_id}`);
        console.log(response);
        if (response === undefined) return;

        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        Getcandidatos(pagination.current_page, search_oficial);



    }

    useEffect(() => {
        Getcandidatos(1, search_oficial);

        return () => {
            clearTimeout(timerRef.current)
        }
    }, []);

    const GetPdfsZip = async () => {

        let response = await Request.Get(`faculdade/download_pdfs_images/` + processo_id);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        // else if(response['status']==undefined){
        //     setLoadingZip(false);

        //     saveAs(response,'redacoes_processo_'+processo_name+'.zip');
        // }
        else {
            if (response['status'] == true) {
                document.getElementById('download_link').href = response['path'];
                document.getElementById('download_link').click();
                setLoadingZip2(false);
                setMessageZip2('');

                return;
            }
            else if (response['status'] != 'Error') {
                setMessageZip2(response['message']);
            }
            timerRef.current = setTimeout(() => {
                GetPdfsZip();
            }, 20000);

        }


    }

    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">
                <a id="download_link" target="_blank"></a>


                {/* <hr /> */}
                {loading_screen == false && <div>
                    <ModalCandidatosCsv onFinish={() => {
                        setLoadingcandidatos(true);
                        Getcandidatos(1, search_oficial);
                    }} showModal={showModal} setShowModal={(e) => setShowModal(e)} processo_id={processo_id} processo_name={processo_name} />

                    <p className="text-muted">Aqui ficam os candidatos vinculados ao processo "{processo_name}"</p>

                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}

                    <div className="flex jcsb flex-wrap mb-4">

                        <Search className="mt-2" placeholder="Pesquisar pelo nome do candidato" ValueChange={value => {
                            clearTimeout(pesquisarTimeout);
                            console.log('VALue:', value)
                            setSearchOficial(value);

                            pesquisarTimeout = setTimeout(() => {
                                let page = { ...pagination };
                                page.current_page = 1;
                                setPagination(pagination);
                                console.log(value)
                                setLoadingcandidatos(true);

                                Getcandidatos(1, value)

                            }, 500);
                        }} />





                        <div className=" mt-2" style={{
                            flexBasis: '50%',
                            height: 'max-content',
                            justifyContent: 'flex-end'
                        }}>


                            <div className="d-flex " style={{
                                justifyContent: 'flex-end'
                            }}>




                                {online == true && user?.empresa?.online_proccess == true && <div onClick={() => {
                                    if (loading_zip == false) {
                                        setLoadingZip(true);
                                        setMessageZip('Criando .zip para download');
                                        GetCandidatosZip();
                                    }
                                    else {

                                    }
                                }} style={{ textDecoration: "none", display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '50%' }}>
                                    {loading_zip == false && <Button variant="success" className="flex align-center jcc me-2"><MdFileDownload style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Baixar todos relatórios</p></Button>}
                                    {loading_zip == true && <div className="me-2" style={{ paddingLeft: 10, paddingRight: 10 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                        {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                        <CircularProgress size={15} />
                                    </div>
                                        <div className="col-12 d-flex justify-content-center align-items-center">
                                            <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: 'darkgoldenrod' }}>{message_zip}</p>
                                        </div>
                                    </div>}
                                </div>}



                                <Link to={{
                                    pathname: "/candidatos_vinculados/register/" + processo_id,
                                    // state: {
                                    //     course_id: id
                                    // }
                                }} style={{ textDecoration: "none" }}>
                                    <Button variant="success" className="flex align-center jcc"><FaPlus style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar candidatos</p></Button>
                                </Link>
                            </div>




                            <div className="mt-2 d-flex justify-content-end" style={{ textDecoration: "none" }}>
                                {online == true && user?.empresa?.online_proccess == true && <div onClick={() => {
                                    if (loading_zip2 == false) {
                                        setLoadingZip2(true);
                                        setMessageZip2('Criando .zip para download');
                                        GetPdfsZip();
                                    }
                                    else {

                                    }
                                }} style={{ textDecoration: "none", display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '50%' }}>
                                    {loading_zip2 == false && <Button variant="success" className="flex align-center jcc me-2"><MdFileDownload style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Baixar .zip de imagens</p></Button>}
                                    {loading_zip2 == true && <div className="me-2" style={{ paddingLeft: 10, paddingRight: 10 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                        {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                        <CircularProgress size={15} />
                                    </div>
                                        <div className="col-12 d-flex justify-content-center align-items-center">
                                            <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: 'darkgoldenrod' }}>{message_zip2}</p>
                                        </div>
                                    </div>}
                                </div>}
                                <Button onClick={() => {
                                    setShowModal(true);
                                }} variant="success" className="flex align-center jcc"><FaListAlt style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Adicionar candidatos via .csv</p></Button>
                            </div>
                        </div>

                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Nome</th>
                                        <th>E-mail</th>
                                        <th>CPF</th>
                                        <th>Número de inscrição</th>
                                        <th>Data de nascimento</th>
                                        <th><OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip`}>
                                                    Quanto menor, mais confiável
                                                </Tooltip>
                                            }
                                        >
                                            <div className="d-flex justify-content-between align-items-center">
                                               Confiabilidade <MdInfo style={{marginLeft:5}}/>
                                            </div>
                                        </OverlayTrigger> </th>

                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_candidatos == false && candidatos.map((item, id) => (

                                        <tr key={id}>
                                            <td>{item.name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.cpf}</td>
                                            <td>{item.numero_inscricao}</td>
                                            <td>{moment(item.nascimento).format('DD/MM/YYYY')}</td>
                                            <td>{item.trust_points ?? '-'}</td>

                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                            <td>

                                                <Link to={`/candidatos_vinculados/show/${item.id}/${processo_id}`} onClick={(e) => e.stopPropagation()}>

                                                    <span

                                                        className="inline-flex align-center jcc bg-warning icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                        title="Gerenciar candidato"
                                                    >
                                                        <BsFillEyeFill size={17} color="white" />
                                                    </span>
                                                </Link>
                                                <span
                                                    onClick={() => {
                                                        GetRelatórioCandidato(item);
                                                    }}
                                                    className="inline-flex align-center jcc bg-info icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Baixar relatório"
                                                >
                                                    <MdFileDownload size={17} color="white" />
                                                </span>
                                                <span
                                                    onClick={() => {
                                                        GetVideoCandidato(item);

                                                    }}
                                                    className="inline-flex align-center jcc bg-success icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Baixar Vídeo"
                                                >
                                                    <MdVideoLibrary size={17} color="white" />
                                                </span>

                                                {item.deleted_at == null && <span
                                                    onClick={(e) => {
                                                        setRedacaoId(item.id);
                                                        setShowWarning(true);
                                                        e.stopPropagation();
                                                    }}
                                                    className="inline-flex align-center jcc bg-danger icon"
                                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                    title="Desvincular candidatos"
                                                >
                                                    <MdDelete size={17} color="white" />
                                                </span>}
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            {loading_candidatos == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_candidatos == false && candidatos.length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhum candidato encontrado</p>

                        </div>
                    </div>}

                    <Pagination
                        className={(pagination.last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={pagination.last_page}
                        value={pagination.current_page}
                        onChange={(_, value) => {
                            setLoadingcandidatos(true);
                            console.log(_, value);
                            let page = { ...pagination };
                            page.current_page = value;
                            setPagination(pagination);
                            Getcandidatos(value, search_oficial)
                        }}
                    />

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_candidatos={this.get_candidatos.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <CircularProgress />
                </div></div>}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        setShowWarning(false);
                        setLoadingcandidatos(true);
                        DeleteRedacao(candidatos_id);
                        // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                        setShowWarning(false);
                    }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo desvincular'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao desvincular este candidato, ele não terá mais acesso a este processo
                </SweetAlert>


            </div>

        </div>
    )
}

export default CandidatosVinculados;
