// Libraries imports
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

// Components imports
import Attention from "../ReusableComponents/Attention";
import DefaultButton from "../ReusableComponents/DefaultButton";

// Utils imports
import { URL } from "../../variables";
import { logout } from "../../actions/AppActions";
import { get } from "../../Services/api";
import { RiArrowGoBackLine } from "react-icons/ri";
import { handleFileChange } from "../../Auxiliar/fileUtils";
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner, ProgressBar } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";

const EditarConteudo = ({ match, token }) => {
  const [path, setPath] = useState("/conteudos");
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [file, setFile] = useState("");
  const [videoFileName, setVideoFileName] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [has_questions, setHasQuestions] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [msgErro, setMsgErro] = useState("");
  const [filePreview, setFilePreview] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [newFile, setNewFile] = useState(null);
  const [newThumbnail, setNewThumbnail] = useState(null);
  const [progress, SetProgress] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const { id } = match.params;
    getContent(id);
  }, [match.params, token]);

  const getContent = async (id) => {
    try {
      const resp = await get(`api/conteudo/get_content/${id}`, token);
      const { name, type, file, thumbnail, has_questions } = resp.data;
      const videoFileName = file.replace("conteudos/", "");
      setLoading(false);
      setName(name);
      setType(type);
      setFile(file);
      setHasQuestions(has_questions);
      setVideoFileName(videoFileName);
      setThumbnail(thumbnail);
    } catch (error) {
      console.error("Erro:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "name":
        setName(value);
        break;
      case "type":
        setType(value);
        setFilePreview(null);
        setVideoPreview(null);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    salvar();
  };

  const handleChangeTypeSelect = (e) => {
    removeFileAllVideo({ preventDefault: () => { } });
    removeFilePDF({ preventDefault: () => { } });
    setType(e.target.value);
  };

  const salvar = () => {
    const { id } = match.params;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("type", type);
    formData.append("delete_thumbnail", (thumbnail == null && thumbnailPreview == null) ? 'deleted' : 'not_deleted');

    formData.append("has_questions", has_questions ? "true" : "false");
    if (newFile !== null) {
      formData.append("newFile", newFile);
    }
    if (newThumbnail !== null) {
      formData.append("newThumbnail", newThumbnail);
    }
    if (newFile == null && file == null) {
      setMsgErro('Para salvar você deve selecioar um vídeo ou arquivo pdf')
      return;
    }
    setLoadingSave(true);
    setMsgErro("");
    axios.post(`${URL}api/conteudo/edit_content/${id}`, formData, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (e) => {
        console.log(e);
        SetProgress((e.loaded / e.total) * 100);
      }
    })
      .then((response) => {
        // if (!response.ok) {
        //     throw new Error('Erro ao cadastrar conteúdo');
        // }
        return response.data;
      })
      .then(resp => {
        if (resp.message == "Unauthenticated.") {
                            localStorage.removeItem('token'); localStorage.removeItem('user');
                            dispatch(logout());
                            return;
                        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);
          setLoadingSave(false);
          setMsgErro(erro)
        }
        else {
          history.push("/conteudos");
        }

        return resp;
      })
      .catch((err) => {
        let resp = err.response.data;
        if (resp.message == "Unauthenticated.") {
                            localStorage.removeItem('token'); localStorage.removeItem('user');
                            dispatch(logout());
                            return;
                        }
        if (resp.errors != null || resp.error != null) {
          let errors = Object.values(resp.errors);
          let erro = '';
          for (let i = 0; i < errors.length; i++) {
            if (i != errors.length - 1)
              erro += errors[i] + '\n';
            else
              erro += errors[i];
          }
          console.log(erro);
          setMsgErro(erro)
        }
        else {
          setMsgErro(
            "Erro ao pegar resposta do servidor. Você está conectado à internet?"
          );
        }
        console.log(err);
        setLoadingSave(false);

      });
  };

  const handlePdfFileChange = (event) => {
    const newFile = event.target.files[0];
    if (newFile) {
      if (newFile !== file) {
        handleFileChange(
          newFile,
          setFilePreview,
          setNewFile,
        );
      }
    }
  };

  const handleVideoFileChange = (event) => {
    const newFile = event.target.files[0];
    if (newFile) {
      if (newFile !== file) {
        handleFileChange(
          newFile,
          setVideoPreview,
          setNewFile,
        );
      }
    }
  };

  const handleCapaImageFileChange = (event) => {
    const newThumbnailFile = event.target.files[0];
    if (newThumbnailFile) {
      if (newThumbnailFile !== thumbnail) {
        handleFileChange(
          newThumbnailFile,
          setThumbnailPreview,
          setNewThumbnail,
        );
      }
    } else {
      setThumbnail(null);
      setThumbnailPreview(null);
    }
  };

  const removeFilePDF = (e) => {
    e.preventDefault();
    setFilePreview(null);
    setFile(null);
  };

  const removeFileAllVideo = (e) => {
    e.preventDefault();
    setVideoPreview(null);
    setFile(null);
    setThumbnailPreview(null);
    setThumbnail(null);
  };

  const removeFileVideo = (e) => {
    e.preventDefault();
    setVideoPreview(null);
    setFile(null);
  };

  const removeFileImage = (e) => {
    e.preventDefault();
    setThumbnailPreview(null);
    setThumbnail(null);
  };


  console.log(file, filePreview)
  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body" id="card">
            <h4 className="mt-0 header-title">Editar Conteúdo</h4>
            <p>Cadastre os conteúdos do seu sistema</p>
            {!loading ? (
              <form onSubmit={handleSubmit}>
                {msgErro && (
                  <div className="alert alert-danger" role="alert">
                    {msgErro}
                  </div>
                )}
                <div className="form-group mb-3">
                  <Form.Label>Nome <Attention /></Form.Label>

                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={name}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mb-3">
                  <Form.Label>Tipo <Attention /></Form.Label>

                  <select
                    className="form-control"
                    id="tipo"
                    value={type}
                    onChange={handleChangeTypeSelect}
                    required
                  >
                    <option value="pdf">PDF</option>
                    <option value="video">Vídeo</option>
                  </select>
                </div>
                {type === "pdf" && (
                  <div className="form-group mb-3">
                    <Form.Label>Upload do PDF (Caso seja o mesmo, não escolha nenhum
                      arquivo) </Form.Label>
                    <div className="input-group">
                      <input
                        type="file"
                        value={''}

                        className="form-control input-custom"
                        id="pdfUpload"
                        accept=".pdf"
                        onChange={handlePdfFileChange}
                      />
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={removeFilePDF}
                      >
                        Limpar arquivo
                      </button>
                    </div>
                    {file && !filePreview && (
                      <div className="mt-3">
                        <Form.Label>Pré-visualização do PDF </Form.Label>
                        <div>

                          <iframe
                            src={`${file}`}
                            title="PDF Viewer"
                            style={{ border: "none", width: 200, maxHeight: 200, objectFit: 'contain' }}
                          />
                        </div>

                      </div>
                    )}
                    {filePreview && (
                      <div className="mt-3">
                        <Form.Label>Pré-visualização do PDF </Form.Label>
                        <div>

                          <iframe
                            src={filePreview}
                            style={{ border: "none", width: 200, maxHeight: 200, objectFit: 'contain' }}
                            frameBorder="0"
                          />
                        </div>
                      </div>

                    )}
                  </div>
                )}
                {type === "video" && (
                  <div className="form-group mb-3">
                    <Form.Label>Upload do Vídeo (Caso seja o mesmo, não escolha nenhum
                      arquivo){" "} </Form.Label>

                    <div className="input-group">
                      <input
                        type="file"
                        className="form-control input-custom"
                        id="videoUpload"
                        value={''}
                        accept=".mp4,.avi,.mov,.wmv,.mkv,.flv,.webm,.mpeg,.mpg,.3gp,.m4v"
                        onChange={handleVideoFileChange}
                      />
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={removeFileVideo}
                      >
                        Limpar
                      </button>
                    </div>
                    <div className="mt-3">
                      <Form.Label>Upload da Capa do Vídeo (Caso seja o mesmo, não escolha
                        nenhum arquivo){" "} </Form.Label>
                      <div className="input-group">
                        <input
                          type="file"
                          value={''}

                          className="form-control input-custom"
                          id="capaImage"
                          accept=".jpg,.jpeg,.png,.bmp,.tiff,.tif,.svg,.webp"
                          onChange={handleCapaImageFileChange}
                        />
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={removeFileImage}
                        >
                          Limpar
                        </button>
                      </div>
                    </div>
                    {(file && !videoPreview) && (
                      <div className="mt-3">
                        <Form.Label>Pré-visualização do Vídeo </Form.Label>
                        <div>
                          <video
                            controls
                            preload="metadata"
                            className="img-thumbnail"
                            style={{ width: 200, height: 200, objectFit: 'contain' }}
                          >
                            <source src={`${file}`} type="video/mp4" />
                            Seu navegador não suporta o elemento de vídeo.
                          </video>
                        </div>
                      </div>
                    )}

                    {(videoPreview) && (
                      <div className="mt-3">
                        <Form.Label>Pré-visualização do Vídeo </Form.Label>
                        <div>
                          <video
                            controls
                            preload="metadata"
                            src={videoPreview}
                            className="img-thumbnail"
                            style={{ width: 200, height: 200, objectFit: 'contain' }}
                          >
                          </video>
                        </div>
                      </div>
                    )}

                    {(thumbnail && !thumbnailPreview) && (
                      <div className="mt-2">
                        <Form.Label>Pré-visualização da Capa  </Form.Label>
                        <div>
                          <img
                            src={thumbnail}
                            style={{ width: 200, height: 200, objectFit: 'contain' }}
                            alt="Pré-visualização"
                            className="img-thumbnail"
                          />
                        </div>

                      </div>
                    )}
                    {(thumbnailPreview) && (
                      <div className="mt-2">
                        <Form.Label>Pré-visualização da Capa  </Form.Label>
                        <div>
                          <img
                            src={thumbnailPreview}
                            style={{ width: 200, height: 200, objectFit: 'contain' }}
                            alt="Pré-visualização"
                            className="img-thumbnail"
                          />
                        </div>

                      </div>
                    )}
                    <button
                      type="button"
                      className="btn btn-danger mt-3"
                      onClick={removeFileAllVideo}
                    >
                      Limpar todos os arquivos
                    </button>
                  </div>
                )}
                <div className="form-check mb-3">
                  {/* <input
                    className="form-check-input"
                    type="checkbox"
                    id="has_questions"
                    checked={has_questions}
                    onChange={() => setHasQuestions(!has_questions)}
                    style={{ cursor: "pointer" }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="has_questions"
                    style={{ cursor: "pointer" }}
                  >
                    Há questões neste conteúdo?
                  </label> */}
                </div>
                {!loadingSave && <div className="d-flex justify-content-between mb-3">
                  <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine />}
                    to="/conteudos"
                  />
                  <DefaultButton
                    type="submit"
                    bg="confirm"
                    text="Salvar conteúdo"
                    loading={loadingSave}
                    disabled={loadingSave}
                  />
                </div>}
                {loadingSave == true && <ProgressBar animated now={progress} label={parseInt(progress) + '%'} />}


              </form>
            ) : (
              <div className="d-flex justify-content-center mt-3">
                <div className="spinner-border text-primary" role="status" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(EditarConteudo);
