import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt, FaPlus, FaVideo } from "react-icons/fa";
import { MdDelete, MdFileDownload, MdModeEdit, MdVideoLibrary } from "react-icons/md";
import { Link, useHistory, useParams } from "react-router-dom";
import Search from "../ReusableComponents/Search";

import { Button } from "react-bootstrap";
import Request from "../../Request";
import Pagination from '@material-ui/lab/Pagination';
import { BsFillEyeFill } from "react-icons/bs";
import { logout } from "../../actions/AppActions";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { saveAs } from 'file-saver';
import ModalCandidatosCsv from "./ModalCandidatosCsv";
import { THIS_URL } from "../../variables";



const CandidatosConfiabilidade = props => {

    const [lesson_cards, SetLessonCards] = useState(null);
    const [loading_screen, setLoadingScreen] = useState(true);
    const [loading_candidatos, setLoadingcandidatos] = useState(false);
    const [search_oficial, setSearchOficial] = useState('');
    const [msgStatus, setMsgStatus] = useState('');
    const [link, setLink] = useState('');



    const [candidatos, setcandidatos] = useState([]);
    const [pagination, setPagination] = useState({ current_page: 1, last_page: 1 });



    const [search, setSearch] = useState('');
    const { processo_id, processo_name } = props;
    const [show_warning, setShowWarning] = useState(false);
    const [candidatos_id, setRedacaoId] = useState(null);


    const [questaoSelect, setQuestaoSelect] = useState({});

    const [showModal, setShowModal] = useState(false);
    let refs = [];
    const dispatch = useDispatch();

    const [loading_zip, setLoadingZip] = useState(false);
    const [message_zip, setMessageZip] = useState('');

    const [loading_zip2, setLoadingZip2] = useState(false);
    const [message_zip2, setMessageZip2] = useState('');
    const timerRef = useRef(null);


    const Getcandidatos = async () => {
        setLoadingScreen(true);
        let response = await Request.Get(`faculdade/get_trust_values/${processo_id}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        if (response.status==true) {
            let candidatos=JSON.parse(response.content).sort(function(a, b) {
                return b.trust_points - a.trust_points;
            })
            setLink(response.link);
            setcandidatos(candidatos);
            setLoadingScreen(false);
            setLoadingcandidatos(false);


        }
        else{
            setMsgStatus(response.message)
            timerRef.current = setTimeout(() => {
                Getcandidatos();
            }, 20000);
        }
    }
 

    useEffect(() => {
        Getcandidatos(1, search_oficial);

        return () => {
            clearTimeout(timerRef.current)
        }
    }, []);

 
    let pesquisarTimeout = null;
    const history = useHistory();
    return (
        <div className="row">

            <div className="col-12">


                {/* <hr /> */}
                {loading_screen == false && <div>

                    <p className="text-muted">Aqui ficam a confiabilidade dos candidatos no "{processo_name}". Quanto menor o valor, mais confiável </p>

                    {/* <h4 className="mt-0 header-title" style={{ fontSize: '1.2rem', color: 'black', textAlign: 'center' }}>Questões</h4> */}
                    {/* <br /> */}

                    <div className="flex jcsb flex-wrap mb-4">

                        <Search className="mt-2" placeholder="Pesquisar pelo nome do candidato" ValueChange={value => {
                            setSearchOficial(value);
                        }} />





                        <div className=" mt-2" style={{
                            height: 'max-content',
                            justifyContent: 'flex-end'
                        }}>


                            <div className="d-flex " style={{
                                justifyContent: 'flex-end'
                            }}>








                                {<a href={link} target="_blank"  style={{ textDecoration: "none", marginRight: 5 }}>
                                    {<Button variant="success" className="flex align-center jcc"><MdFileDownload style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Baixar relatório</p></Button>}

                                </a>}
                            </div>





                        </div>

                    </div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Tipo</th>
                                        <th>Nome</th>
                                        <th>Inscrição</th>

                                        <th>E-mail</th>
                                        <th>CPF</th>
                                        <th>Sala</th>
                                        <th>Status</th>

                                        <th>Confiabilidade</th>

                                        <th>Ações</th>



                                    </tr>
                                </thead>
                                <tbody>
                                    {loading_candidatos == false && candidatos.
                                    filter((item,id)=>{
                                        if(item.name.toLowerCase().includes(search_oficial.toLowerCase())
                                        || item.cpf.toLowerCase().includes(search_oficial.toLowerCase())
                                        || item.numero_inscricao.toLowerCase().includes(search_oficial.toLowerCase())
                                        || item.email.toLowerCase().includes(search_oficial.toLowerCase())
                                        ){
                                            return true;
                                        }
                                        return false;
                                    })
                                    .map((item, id) => (
<>
                                        <tr key={id}>
                                            <td>Candidato</td>

                                            <td>{item.name}</td>
                                            <td>{item.numero_inscricao}</td>
                                            <td>{item.email}</td>
                                            <td>{item.cpf}</td>
                                            <td>Sala {item.sala?.numero}</td>
                                            {item.status == 'Presente' && <td style={{ color: 'green' }}>{'Presente'}</td>}
                                            {item.status != 'Presente' && <td style={{ color: 'red' }}>{'Ausente'}</td>}

                                            <td>
                                                {item.trust_points}
                                            </td>
                                            
                                            <td>

                                                <a target="_blank" href={`${THIS_URL}candidatos_vinculados/show/${item.id}/${processo_id}`} onClick={(e) => e.stopPropagation()}>

                                                    <span

                                                        className="inline-flex align-center jcc bg-warning icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                        title="Gerenciar candidato"
                                                    >
                                                        <BsFillEyeFill size={17} color="white" />
                                                    </span>
                                                </a>
                                            </td>

                                            {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}



                                        </tr>
                                        <tr key={'2'+id}>
                                            <td >
                                                <b>Indices:</b>
                                                 </td>
                                            <td colSpan={3}>

                                                <p style={{marginBottom:2}}><b>Ações reportadas:</b> {item.trust.acoes_relatadas}</p>
                                                <p style={{marginBottom:2}}><b>Média do reconhecimento facial:</b> {100-item.trust.average_face}</p>
                                                <p style={{marginBottom:2}}><b>Validações faciais periódicas:</b> {item.trust.face_validaiton_again}</p>
                                                <p style={{marginBottom:2}}><b>Fotos sem rosto detectado:</b> {item.trust.no_face}</p>
                                                <p style={{marginBottom:2}}><b>Número de pedidos de liberação para o fiscal:</b> {item.trust.request_validation}</p>
                                                
                                            </td>
                                            <td colSpan={3}>

                                            <p style={{marginBottom:2}}><b>Saídas de tela:</b> {item.trust.saidas}</p>
                                                <p style={{marginBottom:2}}><b>Tempo de saída da tela de prova:</b> {item.trust.tempo} segundos</p>
                                                <p style={{marginBottom:2}}><b>Vídeos sem rosto:</b> {item.trust.video_analyse.no_face}</p>
                                                <p style={{marginBottom:2}}><b>Múltiplas rostos:</b> {item.trust.video_analyse.multiple_faces_detected}</p>
                                                <p style={{marginBottom:2}}><b>Ips idênticos:</b> {item.trust.ip_equal}</p>
                                                
                                                </td>



                                        </tr>
</>

                                    ))}

                                </tbody>
                            </table>
                            {loading_candidatos == true &&
                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                    {/* <div className="spinner-border text-primary" role="status"  >
                                    </div> */}
                                    <CircularProgress />
                                </div></div>

                            }
                        </div>
                    </div>
                    {loading_candidatos == false && candidatos.filter((item,id)=>{
                                        if(item.name.toLowerCase().includes(search_oficial.toLowerCase())
                                        || item.cpf.toLowerCase().includes(search_oficial.toLowerCase())
                                        || item.numero_inscricao.toLowerCase().includes(search_oficial.toLowerCase())
                                        || item.email.toLowerCase().includes(search_oficial.toLowerCase())
                                        ){
                                            return true;
                                        }
                                        return false;
                                    }).length == 0 && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhum candidato encontrado</p>

                        </div>
                    </div>}

                    <Pagination
                        className={(pagination.last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={pagination.last_page}
                        value={pagination.current_page}
                        onChange={(_, value) => {
                            setLoadingcandidatos(true);
                            console.log(_, value);
                            let page = { ...pagination };
                            page.current_page = value;
                            setPagination(pagination);
                            Getcandidatos(value, search_oficial)
                        }}
                    />

                    {/* {this.state.andamento == true && <AvaliacaoAndamento get_candidatos={this.get_candidatos.bind(this)} {...this.props} />} */}

                </div>}

                {loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    {/* <div className="spinner-border text-primary" role="status"  >
                    </div> */}
                    <span style={{color:'#3f51b5'}}>{msgStatus}</span>
                    <CircularProgress style={{marginLeft:10}} />
                </div></div>}

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        setShowWarning(false);
                        setLoadingcandidatos(true);
                        // this.delete_banner(this.state.banner_to_delete);
                    }}
                    onCancel={() => {
                        setShowWarning(false);
                    }}
                    show={show_warning}
                    confirmBtnText='Sim, desejo desvincular'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao desvincular este candidato, ele não terá mais acesso a este processo
                </SweetAlert>


            </div>

        </div>
    )
}

export default CandidatosConfiabilidade;
