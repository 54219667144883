// Library Imports
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Dropdown, Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RiArrowGoBackLine, RiCloseCircleLine } from "react-icons/ri";

// Components Imports
import DefaultButton from "../ReusableComponents/DefaultButton";
import Attention from "../ReusableComponents/Attention";

// Utils Imports
import { logout } from "../../actions/AppActions";
import { get, put } from "../../Services/api";
import { MdDelete } from "react-icons/md";

const EditarModulo = ({ token, match, history }) => {
  const [formData, setFormData] = useState({
    redirect: false,
    path: "/modulos",
    loading: true,
    name: "",
    description: "",
    loadingSave: false,
    msgErro: "",
    conteudoIds: [],
    contents: [],
    selectedContents: [],
    dropdownOpen: false,
  });

  const [show_autocomplete, setShowAutoComplete] = useState(false);
  const [content_text, setContentText] = useState('');
  const timeout_ = useRef();
  const candidato_timeout = useRef();
  const [loading_search, setLoadingSearch] = useState(false);

  const [initialContents, setInitialContents] = useState([]);

  const getContent = async (search = '') => {
    setLoadingSearch(true);
    try {
      const data = await get("api/conteudo/get_contents?search=" + search, token);

      setFormData((prevState) => ({
        ...prevState,
        contents: data.data.data || [],
        loading: false,
      }));
    } catch (error) {
      console.log(error.message || "Erro ao buscar conteúdos");
      setFormData((prevState) => ({
        ...prevState,
        msgErro: error.message || "Erro ao buscar conteúdos",
        loading: false,
      }));
    }
    finally {
      setLoadingSearch(false);
    }
  };

  const fetchData = async () => {
    const { id } = match.params;

    try {
      const [moduleData] = await Promise.all([
        get(`api/modulo/get_module/${id}`, token),
      ]);

      const { module } = moduleData;
      const { name, description } = module;

      setFormData((prevState) => ({
        ...prevState,
        name,
        description,
        selectedContents: module.conteudos,
        loading: false,
      }));

      setInitialContents(module.conteudos);
    } catch (error) {
      console.error("Erro:", error);
      setFormData((prevState) => ({
        ...prevState,
        redirect: true,
        loading: false,
      }));
    }
  };

  useEffect(() => {



    fetchData();

    return () => {
    };
  }, [match.params, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (content) => {
    setFormData((prevState) => {
      if (prevState.selectedContents.filter((item) => item.id == content.id).length == 0) {
        prevState.selectedContents.push(content)
      }
      return {
        ...prevState,
      };
    });
    setShowAutoComplete(false)
  };

  const handleRemoveContent = (id) => {
    setFormData((prevState) => {
      const selectedContents = prevState.selectedContents.filter(
        (content) => content.id !== id
      );

      return {
        ...prevState,
        selectedContents,
      };

    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { id } = match.params;
    const { name, description, selectedContents } = formData;

    setFormData((prevState) => ({
      ...prevState,
      loadingSave: true,
      msgErro: "",
    }));

    try {
      let resp = await put(
        `api/modulo/edit_module/${id}`,
        {
          name,
          description,
          conteudo_ids: selectedContents.map((content) => content.id),
        },
        token
      );

      if (resp.status == false) {
        setFormData((prevState) => ({
          ...prevState,
          msgErro: resp.message,
          loadingSave: false
        }));
      }
      else {
        history.push("/modulos");
      }
    } catch (error) {
      setFormData((prevState) => ({
        ...prevState,
        msgErro: error.message || "Erro ao editar módulo",
        loadingSave: false,
      }));
    }
  };

  const toggleDropdown = () => {
    setFormData((prevState) => ({
      ...prevState,
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const conteudos = [...formData.selectedContents];
    const [reorderedItem] = conteudos.splice(result.source.index, 1);
    conteudos.splice(result.destination.index, 0, reorderedItem);

    try {
      await saveOrder(conteudos);
      setFormData((prevState) => ({
        ...prevState,
        selectedContents: conteudos,
      }));
    } catch (error) {
      console.error("Erro ao reordenar:", error);
    }
  };

  const saveOrder = async (conteudos) => {
    const { id } = match.params;
    try {
      await put(
        `api/modulo/edit_module/${id}`,
        {
          name: formData.name,
          description: formData.description,
          conteudo_ids: conteudos.map((conteudo) => conteudo.id),
        },
        token
      );

      setFormData((prevState) => ({
        ...prevState,
        errorMsg: "",
      }));
    } catch (error) {
      console.error(error.message || "Erro ao salvar a nova ordem");
      setFormData((prevState) => ({
        ...prevState,
        errorMsg: error.message || "Erro ao salvar a nova ordem",
      }));
    }
  };

 



  if (formData.redirect) {
    return <Redirect to={formData.path} />;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title mb-3"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              Editar Módulo
            </h4>
            <p className="mb-3" style={{ fontSize: "1rem", color: "black" }}>
              Edite um módulo do seu sistema
            </p>
            {formData.msgErro && (
              <div className="mb-3" style={{ display: "flex", width: "100%" }}>
                <div
                  className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                  role="alert"
                >
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() =>
                      setFormData((prevState) => ({
                        ...prevState,
                        msgErro: "",
                      }))
                    }
                  ></button>
                  <p style={{ marginBottom: 0 }}>{formData.msgErro}</p>
                </div>
              </div>
            )}
            {!formData.loading ? (
              <form onSubmit={handleSubmit}>
                <div className="form-group mb-3">
                  <Form.Label>Nome <Attention /></Form.Label>

                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group mb-3">
                  <Form.Label>Descrição <Attention /></Form.Label>

                  <textarea
                    className="form-control"
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  ></textarea>
                </div>

                {<InputGroup>


                  <div className="flex fdcolumn flex1 margin-bottom">
                    <Form.Label>Busque um ou mais conteúdos que deseja vincular </Form.Label>
                    <Input
                      placeholder="Pesquise pelo nome do conteúdo"
                      value={content_text}
                      // onChange={(e) => this.setState({ candidato_enunciado: e.target.value })}
                      onBlur={() => {
                        timeout_.current = setTimeout(() => {
                          setShowAutoComplete(false)
                        }, 500)
                      }}
                      onFocus={() => {
                        clearTimeout(timeout_.current);
                        setShowAutoComplete(true)
                      }}
                      onChange={(e) => {
                        clearTimeout(candidato_timeout.current);
                        candidato_timeout.current = setTimeout(() => {
                          getContent(e.target.value);
                        }, 1000);
                        setContentText(e.target.value)
                      }}
                    // title="Coloque o nome completo do usuário"
                    />
                    {show_autocomplete == true && content_text != '' && <div className="overflow-auto" style={{ width: '100%', position: 'absolute', zIndex: 20, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', maxHeight: '50vh', top: 70 }}>
                      {loading_search == false && formData.contents.map((item, id) => (
                        <div key={id} onClick={() => handleSelectChange(item)} className="opcao_autocomplete">
                          <b>{item.name}</b> - {item.type}
                        </div>
                      ))}
                      {loading_search == false && formData.contents.length == 0 &&
                        <div className="opcao_autocomplete">
                          Nenhum conteúdo encontrado com este termo
                        </div>
                      }
                      {loading_search == true &&
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                          <div className="spinner-border text-primary" role="status"  >
                          </div></div>}
                    </div>}
                  </div>
                </InputGroup>}

                <InputGroup>

                  <div className="flex fdcolumn flex1 margin-bottom">
                    <Form.Label>Conteúdos vinculados <Attention /></Form.Label>

                    <div className="table-rep-plugin">
                      <div className="table-responsive mb-0" data-pattern="priority-columns">
                         <DragDropContext onDragEnd={onDragEnd}>
                                                        <Droppable droppableId="conteudos">
                                                            {(provided) => (
                        <table id="tech-companies-1" className="table table-striped"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        >
                          <thead>
                            <tr>
                              <th>Nome</th>
                              <th>Tipo</th>
                              <th data-priority="3">Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formData.selectedContents.map((item, id) => (
                              <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={id}>
                                                                                      {(provided) => (
                              <tr ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}>
                                <td>{item.name}</td>
                                <td>{item.type}</td>

                                {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                <td>
                                  {/* <span
                                                                          onClick={() => {
                                                                              this.setState({ candidatoSelect: item, showModal: true });
                                                                          }}
                                                                          className="inline-flex align-center jcc bg-warning icon"
                                                                          style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                          title="Gerenciar aula"
                                                                      >
                                                                          <BsFillEyeFill size={17} color="white" />
                                                                      </span> */}
                                  <span
                                    onClick={(e) => {
                                      handleRemoveContent(item.id)

                                    }}
                                    className="inline-flex align-center jcc bg-danger icon"
                                    style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                    title="Desvincular candidato"
                                  >
                                    <MdDelete size={17} color="white" />
                                  </span>
                                </td>

                              </tr> )}
                                                                                  </Draggable>
                            ))}
                                                {provided.placeholder}

                          </tbody>
                        </table>
                        )}
                                                        </Droppable>
                                                    </DragDropContext>
                      </div>
                    </div>
                    {formData.selectedContents.length == 0 && <div className="row w-100">
                      <div className="col-12">
                        <p style={{ textAlign: 'center' }}>Nenhum conteúdo selecionado</p>

                      </div>
                    </div>}
                  </div>

                </InputGroup>


                <div className="w-100 d-flex justify-content-between mt-3">
                  <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine />}
                    onClick={() => {
                      history.push('/modulos')
                    }}
                  />
                  <DefaultButton
                    type="submit"
                    bg="confirm"
                    text="Salvar módulo"
                    disabled={formData.loadingSave}
                    loadingtext="Salvando módulo"
                    loading={formData.loadingSave}
                  />
                </div>
              </form>
            ) : (
              <div className="row">
                <div
                  className="col-12 d-flex justify-content-center align-items-center"
                  style={{ marginTop: "2rem" }}
                >
                  <div className="spinner-border text-primary" role="status" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(EditarModulo);
