// Libraries Imports
import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Pagination } from "@material-ui/lab";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button, Modal, Form, InputGroup,FormControl as Input, } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaListAlt } from "react-icons/fa";
import { BsFillEyeFill, BsGraphUp } from "react-icons/bs";
import { RiErrorWarningLine } from "react-icons/ri";
import { LuCheckCircle2 } from "react-icons/lu";
import { MdDelete } from "react-icons/md";
import Attention from "../ReusableComponents/Attention";
import { DatePicker, TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
// Components Imports
import Search from "../ReusableComponents/Search";

// Utils Imports
import { URL } from "../../variables";
import { get, post } from "../../Services/api";
import moment from "moment";

class TreinamentosInstituicao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      trainings: [],
      loading: true,
      showDeleteConfirmation: false,
      trainingToDelete: "",
      pagination: {
        current_page: 1,
        last_page: 1,
        total: 0,
        per_page: 30,
      },
      msgErro:'',
      errorMsg: "",
      search: "",
      loading_search: false,
      loading_search_fiscais:false,
      showModal: false,
      selectedTrainingId: null,
      selectedFiscais: {},
      loadingFiscais: false,
      showMessageModal: false,
      messageModalContent: "",
      messageModalTitle: "",
      users: [],
      filteredFiscais:[],
      loadingSave:false,
      endDate: moment().add(1, 'day'),
      startTime: moment(),
      endTime: moment().add(1, 'hour'),
      startDate: moment(),

    };
  }


  componentDidMount() {
    this._isMounted = true;
    this.getTrainings();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getTrainings = async (
    page = this.state.pagination.current_page,
    search = this.state.search,
    busca = true
  ) => {
    const { faculdade_id, token } = this.props;
    const { loading_search, loading } = this.state;

    if (busca && !loading_search) {
      this.setState({ loading_search: true });
    } else if (!busca && !loading) {
      this.setState({ loading: true });
    }

    try {
      const response = await get(
        `api/faculdade/exibir_vinculacoes/${faculdade_id}?page=${page}&search=${search}`,
        token
      );
      console.log(response)

      if (this._isMounted) {
        const trainings = response.data.map((training) => ({
          ...training,
        }));

        this.setState({
          trainings: trainings,
          loading: false,
          loading_search: false,
          pagination: {
            current_page: response.pagination.current_page,
            last_page: response.pagination.last_page,
            total: response.pagination.total,
            per_page: response.pagination.per_page,
          },
        });
      }
    } catch (error) {
      console.error(error);
      if (this._isMounted) {
        this.setState({ redirect: true });
      }
    }
  };

  

  handleConfirm = async (trainingToDelete) => {
    try {
      await this.handleDeleteTraining(trainingToDelete);
    } catch (error) {
      console.error("Erro ao executar operações:", error.message);
    }
  };

 
  
  handleDeleteTraining = async (trainingId) => {
    const { faculdade_id, token } = this.props;

    try {
      const response = await post(
        `api/faculdade/desvincular_treinamento`,
        {
          faculdade_id: faculdade_id,
          treinamento_id: trainingId,
        },
        token
      );

      if (response.error) {
        this.setState({
          errorMsg: response.error,
          showDeleteConfirmation: false,
        });
      } else {
        this.setState({ showDeleteConfirmation: false });
        this.getTrainings();
      }
    } catch (error) {
      console.error(error);
      if (this._isMounted) {
        this.setState({ redirect: true });
      }
    }
  };

  getFiscais = async (faculdadeId,search=this.state.search) => {
    this.setState({loading_search_fiscais:true})
    try {
      const data = await get(
        `api/faculdade/get_fiscais/${faculdadeId}?search=${search}`,
        this.props.token
      );

      const fiscais = data.fiscais || [];

      this.setState({
        
        loading_search_fiscais: false,
        filteredFiscais:fiscais
      });
    } catch (error) {
      this.setState({
        
        loading_search_fiscais: false,
      });
    }
  };

  

  
  updateTraining = async () => {
    const { selectedTrainingId,  startDate, endDate, startTime, endTime,selectedFiscais } = this.state;

    // if (Object.keys(selectedFiscais).length === 0) {
    //   this.setState({
    //     errorMsg: "Por favor, selecione pelo menos um fiscal.",
    //     showMessageModal: true,
    //     messageModalTitle: "Erro!",
    //     messageModalContent: "Por favor, selecione pelo menos um fiscal.",
    //   });
    //   return;
    // }
    this.setState({loadingSave:true});
    try {
      const selectedTraining = this.state.trainings.find(
        (training) => training.id === selectedTrainingId
      );
      const response = await post(
        `api/treinamento/update_training/${selectedTrainingId}`,
        {
          users: Object.keys(selectedFiscais),
          data_inicial: startDate.format('YYYY-MM-DD') + ' ' + startTime.format('HH:mm:ss'),
          data_final: endDate.format('YYYY-MM-DD') + ' ' + endTime.format('HH:mm:ss'),
          faculdade_id: this.props.faculdade_id,
          instituicao_treinamento_id:selectedTraining.instituicoes[0].id
        },
        this.props.token
      );

      if(response.status==false){
        this.setState({msgErro:response.message,loadingSave:false,})
        return;
      }


      this.setState({
        errorMsg: "",
        msgErro:'',
        showMessageModal: true,
        loadingSave:false,
        messageModalTitle: "Sucesso!",
        messageModalContent: "Configuração salva com sucesso!",
      });
      this.hideModal();
      this.getTrainings(this.state.pagination.current_page);
    } catch (error) {
      console.error(error.message || "Erro ao atualizar o treinamento");
      this.setState({
        errorMsg: error.message || "Erro ao atualizar o treinamento",
        showMessageModal: true,
        loadingSave:false,
        messageModalTitle: "Erro!",
        messageModalContent: error.message || "Erro ao atualizar o treinamento",
      });
    }
  };

  getUsersByTraining = async (selectedTraining) => {


    try {
      const data = await get(
        `api/treinamento/fiscal/${selectedTraining.instituicoes[0].id}`,
        this.props.token
      );

      // data.users.

      return data.users;
    } catch (error) {
      console.error("Erro ao buscar usuários por treinamento:", error.message);
      throw error;
    }
  };

  

  handleSearchChange = (value) => {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.setState(
        {
          search: value,
          pagination: { ...this.state.pagination, current_page: 1 },
          loading_search: true,
        },
        this.getTrainings
      );
    }, 500);
  };

  handlePageChange = (event, value) => {
    this.setState(
      { pagination: { ...this.state.pagination, current_page: value } },
      this.getTrainings
    );
  };

  handleShowModal = async (trainingId) => {
    try {
      this.setState({
        showModal: true,
        loadingFiscais: true,
        selectedTrainingId: trainingId,

      });

      const { trainings } = this.state;
      const selectedTraining = trainings.find(
        (training) => training.id === trainingId
      );
      if (selectedTraining) {
        this.setState({
          endDate: selectedTraining.instituicoes[0]?.data_final ? moment(selectedTraining.instituicoes[0]?.data_final) : moment().add(1, 'day'),
          startTime: selectedTraining.instituicoes[0]?.data_inicial ? moment(selectedTraining.instituicoes[0]?.data_inicial) : moment().add(1, 'hour'),
          endTime: selectedTraining.instituicoes[0]?.data_final ? moment(selectedTraining.instituicoes[0]?.data_final) : moment().add(1, 'hour'),
          startDate: selectedTraining.instituicoes[0]?.data_inicial ? moment(selectedTraining.instituicoes[0]?.data_inicial) : moment(),
        });
      }

      // await this.getFiscais(this.props.faculdade_id);

      const users = await this.getUsersByTraining(selectedTraining);
      const selectedFiscais = {};
      users.forEach((value, index) => selectedFiscais[value.id] = value);

      this.setState({
        loadingFiscais: false,
        selectedFiscais

      });
    } catch (error) {
      console.error("Erro ao carregar os dados do modal:", error);

      this.setState({ loadingFiscais: false });
    }
  };

  hideModal = () => {
    this.setState({
      showModal: false,
      selectedTrainingId: null,
      endDate: moment().add(1, 'day'),
      startTime: moment(),
      endTime: moment().add(1, 'hour'),
      startDate: moment(),
      selectedFiscais:{},
      filteredFiscais:[],
      search:''
    });
  };

  hideMessageModal = () => {
    this.setState({
      showMessageModal: false,
      messageModalTitle: "",
      messageModalContent: "",
    });
  };

  handleStartDateChange = (e) => {
    this.setState({ startDate: e.target.value });
  };

  handleEndDateChange = (e) => {
    this.setState({ endDate: e.target.value });
  };

  handleFiscalSelection = (fiscal) => {
    const selectedFiscais = { ...this.state.selectedFiscais };
      selectedFiscais[fiscal.id] = fiscal;
    this.setState({
        selectedFiscais:selectedFiscais,
        show_autocomplete:false
    });
  };

  

  

  renderTrainingsTable = () => {
    const { trainings, loading_search, loading } = this.state;

    if (loading || loading_search) {
      return (
        <div className="d-flex justify-content-center">
          <CircularProgress />
        </div>
      );
    }

    return (
      <>
        <div className="table-rep-plugin">
          <div className="table-responsive mb-0" data-pattern="priority-columns">
            <table id="tech-companies-1" className="table table-striped">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Período</th>
                  {/* <th>Descrição</th> */}
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {!loading_search &&
                  trainings.map((training, index) => (
                    <tr key={index}>
                      <td>{training.nome}</td>
                      <td>{!training.instituicoes[0]?.data_inicial ? '-' : (moment(training.instituicoes[0]?.data_inicial).format('DD/MM/YYYY HH:mm') + ' - ' + moment(training.instituicoes[0]?.data_final).format('DD/MM/YYYY HH:mm'))}</td>
                      <td style={{color:training.status=='Não iniciado'?'darkgoldenrod':(training.status=='Encerrado'?'red':'green')}}>{training.status}</td>
                      <td>
                        <Link
                          to={`/treinamento/dashboard/${training.id}`}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <span
                            className="inline-flex align-center jcc bg-success icon"
                            style={{
                              width: "2em",
                              height: "2em",
                              borderRadius: "7px",
                              marginRight: "2px",
                            }}
                            title="Gerenciar dashboard"
                          >
                            <BsGraphUp size={17} color="white" />
                          </span>
                        </Link>
                        <span
                          onClick={() => this.handleShowModal(training.id)}
                          className="inline-flex align-items-center justify-content-center bg-warning icon"
                          style={{
                            width: "2em",
                            height: "2em",
                            borderRadius: "7px",
                            marginRight: "2px",
                          }}
                          title="Gerenciar"
                        >
                          <BsFillEyeFill size={17} color="white" />
                        </span>
                        <span
                          onClick={() =>
                            this.setState({
                              showDeleteConfirmation: true,
                              trainingToDelete: training.id,
                            })
                          }
                          className="inline-flex align-items-center justify-content-center bg-danger icon"
                          style={{
                            width: "2em",
                            height: "2em",
                            borderRadius: "7px",
                          }}
                          title="Deletar treinamento"
                        >
                          <MdDelete size={17} color="white" />
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {loading_search == false && trainings.length == 0 && <div className="row w-100">
          <div className="col-12">
            <p style={{ textAlign: 'center' }}>Nenhum treinamento encontrado</p>

          </div>
        </div>}
      </>
    );
  };

  render() {
    const {
      redirect,
      showDeleteConfirmation,
      trainingToDelete,
      errorMsg,
      pagination,
      showModal,
      formData,
      loadingFiscais,
      showMessageModal,
      messageModalTitle,
      messageModalContent,
      filteredFiscais
    } = this.state;
    const { user, faculdade_name, faculdade_id } = this.props;

    if (redirect) {
      return <Redirect to="/" />;
    }

   

    return (
      <div className="row">
        <div className="col-12">
          <p className="text-muted">
            Aqui ficam{" "}
            {user.empresa == null || user.empresa.change_text == false
              ? "os treinamentos da instituição"
              : "as vagas/cargos do processo"}{" "}
            "{faculdade_name}"
          </p>
          <div className="flex jcsb flex-wrap mb-4">
            <Search
              placeholder="Pesquisar pelo nome do treinamento"
              ValueChange={this.handleSearchChange}
            />
            <Link
              to={`/instituicao/link/${faculdade_id}`}
              style={{ textDecoration: "none" }}
            >
              <Button variant="success" className="flex align-center jcc">
                <FaListAlt style={{ marginRight: 10 }} />
                <p style={{ margin: 0 }}>Adicionar treinamento</p>
              </Button>
            </Link>
          </div>
          <div className="table-rep-plugin">{this.renderTrainingsTable()}</div>
          <Pagination
            className={pagination.last_page === 1 ? "hide" : ""}
            style={{ display: "flex", justifyContent: "flex-end" }}
            count={pagination.last_page}
            page={pagination.current_page}
            onChange={this.handlePageChange}
          />

          <SweetAlert
            warning
            title={"Atenção"}
            show={showDeleteConfirmation}
            onConfirm={() => this.handleConfirm(trainingToDelete)}
            onCancel={() => this.setState({ showDeleteConfirmation: false })}
            confirmBtnText="Sim, desejo desvincular"
            cancelBtnText="Cancelar"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            showCancel={true}
          >
            {errorMsg
              ? errorMsg
              : "Ao desvincular este treinamento, ele não estará mais disponível na instituição"}
          </SweetAlert>

          <Modal size="xl" show={showModal} onHide={this.hideModal}>
            <Modal.Header closeButton>
              <Modal.Title>Gerenciar Treinamento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {loadingFiscais ? (
                <div className="d-flex justify-content-center">
                  <CircularProgress />
                </div>
              ) : (
                <Form>
                  {this.state.msgErro !== "" && (
              <div style={{ display: "flex", width: "100%", marginBottom: 15 }}>
                <div
                  className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                  role="alert"
                >
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() =>
                      this.setState({msgErro:''})
                    }
                  ></button>
                  <p style={{ marginBottom: 0 }}>{this.state.msgErro}</p>
                </div>
              </div>
            )}
                  <MuiPickersUtilsProvider utils={MomentUtils}>

                    <InputGroup>

                      <div className="flex fdcolumn flex1 margin-bottom me-3">
                        <Form.Label>Data de início <Attention /></Form.Label>
                        <DatePicker
                          autoOk
                          margin="normal"
                          style={{ width: '100%' }}
                          invalidDateMessage="Data inválida"
                          ampm={false}
                          minDate={moment()}
                          id="time-picker"
                          cancelLabel="Cancelar"
                          clearLabel="Limpar"
                          format="DD/MM/YYYY"
                          value={this.state.startDate}
                          onChange={(date) => { this.setState({ startDate: date }) }}

                        />
                      </div>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>Horário de início <Attention /></Form.Label>
                        <TimePicker
                          autoOk
                          margin="normal"
                          style={{ width: '100%' }}
                          invalidDateMessage="Horário inválido"
                          ampm={false}
                          id="time-picker"
                          cancelLabel="Cancelar"
                          clearLabel="Limpar"
                          label=""
                          value={this.state.startTime}
                          onChange={(date) => { this.setState({ startTime: date }) }}

                        />
                      </div>
                    </InputGroup>

                    <InputGroup>

                      <div className="flex fdcolumn flex1 margin-bottom me-3">
                        <Form.Label>Data de encerramento <Attention /></Form.Label>
                        <DatePicker
                          autoOk
                          margin="normal"
                          style={{ width: '100%' }}
                          invalidDateMessage="Data inválida"
                          ampm={false}
                          minDate={moment()}
                          id="time-picker"
                          cancelLabel="Cancelar"
                          clearLabel="Limpar"
                          format="DD/MM/YYYY"
                          value={this.state.endDate}
                          onChange={(date) => { this.setState({ endDate: date }) }}

                        />
                      </div>
                      <div className="flex fdcolumn flex1 margin-bottom">
                        <Form.Label>Horário de encerramento <Attention /></Form.Label>
                        <TimePicker
                          autoOk
                          margin="normal"
                          style={{ width: '100%' }}
                          invalidDateMessage="Horário inválido"
                          ampm={false}
                          id="time-picker"
                          cancelLabel="Cancelar"
                          clearLabel="Limpar"
                          label=""
                          value={this.state.endTime}
                          onChange={(date) => { this.setState({ endTime: date }) }}

                        />
                      </div>
                    </InputGroup>



                  </MuiPickersUtilsProvider>


                 
                  {<InputGroup>


                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Busque um ou mais fiscais que deseja vincular no treinamento </Form.Label>
                      <Input
                        placeholder="Pesquise pelo nome do fiscal"
                        value={this.state.search}
                        // onChange={(e) => this.setState({ candidato_enunciado: e.target.value })}
                        onBlur={() => {
                          this.timeout_ = setTimeout(() => {
                            this.setState({show_autocomplete:false});
                          }, 500)
                        }}
                        onFocus={() => {
                          clearTimeout(this.timeout_);
                          this.setState({show_autocomplete:true});
                        }}
                        onChange={(e) => {
                          clearTimeout(this.candidato_timeout);
                          this.candidato_timeout = setTimeout(() => {
                            this.getFiscais(this.props.faculdade_id,e.target.value);
                          }, 1000);
                          this.setState({search:e.target.value})
                        }}
                      // title="Coloque o nome completo do usuário"
                      />
                      {this.state.show_autocomplete == true && this.state.search != '' && <div className="overflow-auto" style={{ width: '100%', position: 'absolute', zIndex: 20, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', maxHeight: '50vh', top: 70 }}>
                        {this.state.loading_search_fiscais == false && filteredFiscais.map((item, id) => (
                          <div key={id} onClick={() => this.handleFiscalSelection(item)} className="opcao_autocomplete">
                            <b>{item.name}</b> - {item.email}
                          </div>
                        ))}
                        {this.state.loading_search_fiscais == false && filteredFiscais.length == 0 &&
                          <div className="opcao_autocomplete">
                            Nenhum fiscal encontrado com este termo
                          </div>
                        }
                        {this.state.loading_search_fiscais == true &&
                          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <div className="spinner-border text-primary" role="status"  >
                            </div></div>}
                      </div>}
                    </div>
                  </InputGroup>}

                  <InputGroup>

                    <div className="flex fdcolumn flex1 margin-bottom">
                      <Form.Label>Fiscais vinculados </Form.Label>

                      <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">

                          <table id="tech-companies-1" className="table table-striped"

                          >
                            <thead>
                              <tr>
                                <th>Nome</th>
                                <th>E-mail</th>
                                <th>Tipo</th>
                            <th data-priority="3">Ações</th>

                              </tr>
                            </thead>
                            <tbody>
                              {Object.values(this.state.selectedFiscais).map((item, id) => (
                                <tr key={id}>
                                  
                                  <td>{item.name}</td>
                                  <td>{item.email}</td>
                                  <td>{item?.role?.description}</td>
                                  <td>
<span
                                  onClick={(e) => {
                                    let selectedFiscais = {...this.state.selectedFiscais}
                                    delete selectedFiscais[item.id];
                                    this.setState({selectedFiscais})
                                  }}
                                  className="inline-flex align-center jcc bg-danger icon"
                                  style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                  title="Desvincular candidato"
                                >
                                  <MdDelete size={17} color="white" />
                                </span>
                                </td>
                                  

                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {Object.values(this.state.selectedFiscais).length == 0 && <div className="row w-100">
                        <div className="col-12">
                          <p style={{ textAlign: 'center' }}>Nenhum fiscal selecionado</p>

                        </div>
                      </div>}
                    </div>

                  </InputGroup>
                </Form>
              )}
            </Modal.Body>
            <Modal.Footer>
              {!this.state.loadingSave ? <>
              <Button variant="secondary" onClick={this.hideModal}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={this.updateTraining}>
                Salvar
              </Button>
            </>:<CircularProgress/>}

            </Modal.Footer>

          </Modal>

          

          <SweetAlert
            success={messageModalTitle == "Sucesso!"}
            danger={messageModalTitle != "Sucesso!"}
            title={messageModalTitle}
            show={showMessageModal}
            onConfirm={this.hideMessageModal}
            onCancel={this.hideMessageModal}
            confirmBtnText="Entendi"
            confirmBtnBsStyle={messageModalTitle === "Sucesso!"?"success":'danger'}
          >
            {messageModalContent}
          </SweetAlert>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.AppReducer.user,
  token: state.AppReducer.token,
});

export default connect(mapStateToProps)(TreinamentosInstituicao);
