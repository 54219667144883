// Libraries imports
import React, { useState, useEffect, useRef } from "react";
import { Dropdown, Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Redirect } from "react-router-dom";

// Components imports
import DefaultButton from "../ReusableComponents/DefaultButton";
import { RiArrowGoBackLine, RiCloseCircleLine } from "react-icons/ri";
import Skeleton from "@material-ui/lab/Skeleton";
import Attention from "../ReusableComponents/Attention";

// Utils imports
import { get, post } from "../../Services/api";
import { logout } from "../../actions/AppActions";
import { MdDelete } from "react-icons/md";

const CriarTreinamento = ({ token,history }) => {
  const [formData, setFormData] = useState({
    nome: "",
    descricao: "",
    loadingSave: false,
    msgErro: "",
    modulos: [],
    loading: false,
    modulo_ids: [],
    selectedModulos: [],
    redirect: false,
  });

  const [show_autocomplete, setShowAutoComplete] = useState(false);
    const [content_text, setContentText] = useState('');
    const timeout_ = useRef();
    const candidato_timeout = useRef();
    const [loading_search, setLoadingSearch] = useState(false);


    const getModulos = async (search='') => {
      setLoadingSearch(true);
      try {
        const data = await get("api/modulo/get_modules?search="+search, token);

        if (data.errors) {
          let errors = Object.values(data.errors);
          let errorMsg = errors.join("\n");
          console.log(errorMsg);
        } else {
          setFormData((prevState) => ({
            ...prevState,
            modulos: data.data,
            loading: false,
          }));
        }
      } catch (error) {
        if (error.name !== "AbortError") {
          console.log(error.message || "Erro ao buscar módulos");
          setFormData((prevState) => ({
            ...prevState,
            msgErro: error.message || "Erro ao buscar módulos",
            loading: false,
          }));
        }
      }
      finally{
        setLoadingSearch(false);
      }
    };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    


    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (content) => {
    setFormData((prevState) => {
      if (prevState.selectedModulos.filter((item) => item.id == content.id).length == 0) {
        prevState.selectedModulos.push(content)
      }
      return {
        ...prevState,
      };
    });
  };

  const salvar = async (e) => {
    e.preventDefault();

    setFormData((prevState) => ({
      ...prevState,
      loadingSave: true,
      msgErro:''
    }));

    try {
      const requestBody = {
        nome: formData.nome,
        descricao: formData.descricao,
        modulo_ids: formData.selectedModulos.map((modulo) => modulo.id),
      };

      const resp = await post(
        "api/treinamento/store_training",
        requestBody,
        token
      );

      if(resp.status==false){
        setFormData((prevState) => ({
          ...prevState,
          msgErro: resp.message,
          loadingSave:false
        }));
      }
      else{
        history.push("/treinamentos");
      }
    } catch (error) {
      setFormData((prevState) => ({
        ...prevState,
        msgErro: error.message || "Erro ao cadastrar treinamento",
      }));
    } 
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const selectedModulos = [...formData.selectedModulos];
    const [reorderedItem] = selectedModulos.splice(result.source.index, 1);
    selectedModulos.splice(result.destination.index, 0, reorderedItem);

    setFormData((prevState) => ({
      ...prevState,
      selectedModulos,
    }));
  };

  const handleRemoveModulo = (id) => {
    setFormData((prevState) => {
      const modulo_ids = prevState.modulo_ids.filter(
        (moduloId) => moduloId !== id
      );
      const selectedModulos = prevState.selectedModulos.filter(
        (modulo) => modulo.id !== id
      );

      return {
        ...prevState,
        modulo_ids,
        selectedModulos,
      };
    });
  };

  if (formData.redirect) {
    return <Redirect to="/treinamentos" />;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div
            className="card-body card-body-custom"
            id="card"
            style={{ overflow: "visible" }}
          >
            <h4
              className="mt-0 header-title header-title-custom"
              style={{ fontSize: "1.5rem", color: "black" }}
            >
              Cadastrar Treinamento
            </h4>
            <p
              className="paragraph-custom"
              style={{ fontSize: "1rem", color: "black" }}
            >
              Cadastre os treinamentos do seu sistema
            </p>
            {formData.msgErro && (
              <div style={{ display: "flex", marginBottom: 15, width: "100%" }}>
                <div
                  className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                  role="alert"
                >
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() =>
                      setFormData((prevState) => ({
                        ...prevState,
                        msgErro: "",
                      }))
                    }
                  ></button>
                  <p style={{ marginBottom: 0 }}>{formData.msgErro}</p>
                </div>
              </div>
            )}
            {!formData.loading ? (
              <form onSubmit={salvar} className="position-relative">
                <div className="form-group flex fdcolumn flex1 margin-bottom">
                <Form.Label>Nome <Attention /></Form.Label>

                  <input
                    type="text"
                    className="form-control"
                    id="nome"
                    name="nome"
                    placeholder="Nome do treinamento"
                    value={formData.nome}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group flex fdcolumn flex1 margin-bottom">
                <Form.Label>Descrição <Attention /></Form.Label>

                  <textarea
                    className="form-control"
                    id="descricao"
                    name="descricao"
                    placeholder="Descrição do treinamento"
                    value={formData.descricao}
                    onChange={handleChange}
                    required
                  />
                </div>

                


                {<InputGroup>


                  <div className="flex fdcolumn flex1 margin-bottom">
                    <Form.Label>Busque um ou mais módulos que deseja vincular </Form.Label>
                    <Input
                      placeholder="Pesquise pelo nome do conteúdo"
                      value={content_text}
                      // onChange={(e) => this.setState({ candidato_enunciado: e.target.value })}
                      onBlur={() => {
                        timeout_.current = setTimeout(() => {
                          setShowAutoComplete(false)
                        }, 500)
                      }}
                      onFocus={() => {
                        clearTimeout(timeout_.current);
                        setShowAutoComplete(true)
                      }}
                      onChange={(e) => {
                        clearTimeout(candidato_timeout.current);
                        candidato_timeout.current = setTimeout(() => {
                          getModulos(e.target.value);
                        }, 1000);
                        setContentText(e.target.value)
                      }}
                    // title="Coloque o nome completo do usuário"
                    />
                    {show_autocomplete == true && content_text != '' && <div className="overflow-auto" style={{ width: '100%', position: 'absolute', zIndex: 20, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', maxHeight: '50vh', top: 70 }}>
                      {loading_search == false && formData.modulos.map((item, id) => (
                        <div key={id} onClick={() => handleSelectChange(item)} className="opcao_autocomplete">
                          <b>{item.name}</b> - {item.description}
                        </div>
                      ))}
                      {loading_search == false && formData.modulos.length == 0 &&
                        <div className="opcao_autocomplete">
                          Nenhum módulo encontrado com este termo
                        </div>
                      }
                      {loading_search == true &&
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                          <div className="spinner-border text-primary" role="status"  >
                          </div></div>}
                    </div>}
                  </div>
                </InputGroup>}

                <InputGroup>

                  <div className="flex fdcolumn flex1 margin-bottom">
                    <Form.Label>Módulos vinculados <Attention /></Form.Label>

                    <div className="table-rep-plugin">
                      <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId="conteudos">
                            {(provided) => (
                              <table id="tech-companies-1" className="table table-striped"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                <thead>
                                  <tr>
                                    <th>Nome</th>
                                    <th>Descrição</th>
                                    <th data-priority="3">Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {formData.selectedModulos.map((item, id) => (
                                    <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={id}>
                                      {(provided) => (
                                        <tr ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}>
                                          <td>{item.name}</td>
                                          <td>{item.description}</td>

                                          {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                          <td>
                                            {/* <span
                                                                          onClick={() => {
                                                                              this.setState({ candidatoSelect: item, showModal: true });
                                                                          }}
                                                                          className="inline-flex align-center jcc bg-warning icon"
                                                                          style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                          title="Gerenciar aula"
                                                                      >
                                                                          <BsFillEyeFill size={17} color="white" />
                                                                      </span> */}
                                            <span
                                              onClick={(e) => {
                                                handleRemoveModulo(item.id)

                                              }}
                                              className="inline-flex align-center jcc bg-danger icon"
                                              style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                              title="Desvincular candidato"
                                            >
                                              <MdDelete size={17} color="white" />
                                            </span>
                                          </td>

                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </tbody>
                              </table>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    </div>
                    {formData.selectedModulos.length == 0 && <div className="row w-100">
                      <div className="col-12">
                        <p style={{ textAlign: 'center' }}>Nenhum módulo selecionado</p>

                      </div>
                    </div>}
                  </div>

                </InputGroup>

                <div className="w-100 d-flex justify-content-between mt-3">
                  <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine />}
                    to="/treinamentos"
                  />

                  <DefaultButton
                    type="submit"
                    bg="confirm"
                    text="Salvar treinamento"
                    disabled={formData.loadingSave}
                    loadingtext="Salvando treinamento"
                    loading={formData.loadingSave}
                  />
                </div>
              </form>
            ) : (
              <div>
                <Skeleton height={40} style={{ marginBottom: "1rem" }} />
                <Skeleton height={40} style={{ marginBottom: "1rem" }} />
                <Skeleton height={40} style={{ marginBottom: "1rem" }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(CriarTreinamento);
