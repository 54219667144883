import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';

import { Redirect } from 'react-router-dom';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import { API_URL, STORAGE_URL, URL } from '../../variables';
import { logout } from '../../actions/AppActions';
import Skeleton from '@material-ui/lab/Skeleton';
import JoditEditor from 'jodit-react';
import Attention from '../ReusableComponents/Attention';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ModalFormula from '../CategoriasQuestoes/ModalFormula';
import FormulaFx from '../../assets/images/formula-4.png';

class EditarQuestaoConteudo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/conteudo/show/',
            nome: '',
            enunciado: '',
            loadingSave: false,
            msgErro: '',
            mounted: false,
            enunciadoInicial: '',
            alternativas: Array(5).fill(''),
            quantidadeAlternativas: 5,
            alternativaCorreta: '',
            loading:true,
            content:{},
            loading_image:false,
            showModalFormula:false
        };
        this.editorOptions = {
            height: 400,
                askBeforePasteHTML: false,
                askBeforePasteFromWord: false,
                defaultActionOnPaste: 'insert_only_text',
                defaultActionOnPasteFromWord: 'insert_only_text',
                pasteHTMLActionList: [
                    { value: 'insert_only_text', text: 'Insert only Text' }
                ],
                pasteFromWordActionList: [
                    { value: 'insert_only_text', text: 'Insert only Text' }
                ],
                readonly: this.state.loading_image,
                language: 'pt_br',
                toolbarButtonSize: 'large',
                removeButtons: ['video', 'fullsize', 'source', 'copyformat', 'about', 'link', 'classSpan'],
                toolbarAdaptive: false,
                extraButtons: [
            {
                name: 'equation',
          tooltip: 'Equações',
                iconURL: FormulaFx,
                exec:  (editor)=> {
                    this.setState({showModalFormula:true});
                    this.editor_select=editor;
                    // if (editor.selection.isCollapsed()) {
                    // 	editor.execCommand('selectall');
                    // }
                    // editor.execCommand('copy');
                    // Jodit.Alert('Text in your clipboard');
                    console.log('clicou')
                }
            }],
                // "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
                uploader: {
                    url: API_URL + 'faculdade/upload_image',  //your upload api url
                    insertImageAsBase64URI: false, //not inster base64
                    imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                    headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                    filesVariableName: function (t) {
                        return 'imagem';
                    }, //"files",
                    withCredentials: false,
                    pathVariableName: 'path',
                    //   format: 'json',
                    method: 'POST',
                    prepareData: (formdata) => {
                        console.log(formdata)
                        this.setState({ loading_image: true });
                        return formdata;
                    },
                    isSuccess: (e) => {
                        console.log(e);
    
                        return 'Upload realizado com sucesso';
                    },
                    getMessage: (e) => {
                        console.log(e);
    
                        return e;
                    },
                    process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                        let files = [];
                        console.log(resp);
                        files.unshift(STORAGE_URL + resp.imagem);
                        this.setState({ loading_image: false });
    
                        return {
                            files: files,
    
                        };
                    },
                    error: (elem, e) => {
                        // elem.j.e.fire('errorMessage', e.message, 'error', 4000);
    
                        console.log(e);
                        console.log(elem);
                        console.log('Bearer ' + localStorage.getItem('token'));
                    },
                    defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                        if (resp.files && resp.files.length) {
                            resp.files.forEach((filename, index) => { //edetor insertimg function
                                if (filename.includes('.mp4')) {
                                    this.selection.insertHTML(`<video controls height="500" width="500" src="${filename}"></video>`)
                                }
                                else if (filename.includes('.mp3')) {
                                    this.selection.insertHTML(`<audio controls src="${filename}"></audio>`)
                                }
                                else {
                                    this.selection.insertImage(filename);
                                }
                            });
                        }
    
                        // debugger;
    
                    },
                    defaultHandlerError: (elem, e) => {
                        // elem.j.e.fire('errorMessage', e.message);
                        console.log(e);
                        console.log(elem);
                        this.setState({ loading_image: false });
    
                    },
                    //   contentType: function (e) {
                    //     return (
    
                    //       'application/x-www-form-urlencoded; charset=UTF-8'
                    //     );
                    //   },
                },
        };
        this.alternativasRefs = Array.from({ length: 5 }, () => createRef());
                this.enunciadoRef = createRef();
    }

    handleUnauthenticated(){
            localStorage.removeItem('token'); localStorage.removeItem('user');
            this.props.logout();
        }

    componentDidMount() {

        const { id } = this.props.match.params;
        this.getQuestion(id);
    }

    componentWillUnmount() {
    }

    async getQuestion(id) {
        try {
            const response = await fetch(`${URL}api/conteudo/get_question/${id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${this.props.token}`
                },
            });
            

            const data = await response.json();

            if (data.message === "Unauthenticated.") {
                this.handleUnauthenticated();
                return;
            }
            const questao = data.questao;

            const quantidadeAlternativas = questao.alternativas.length;
            this.setState({
                loading: false,
                nome: questao.nome,
                enunciado: questao.enunciado,
                alternativas: questao.alternativas,
                quantidadeAlternativas: quantidadeAlternativas,
                alternativaCorreta: questao.correta,
            });

        } catch (error) {
            if (this.state.mounted) {
                this.setState({ redirect: true });
            }
        }
    }

    removeHtmlTags(str) {
        return str ? str.replace(/<[^>]*>/g, '') : '';
    }

    async salvar() {
        this.setState({ loadingSave: true, msgErro: '' });
        const { nome, enunciado, alternativas,alternativaCorreta } = this.state;

        const text_enunciado_questao = this.removeHtmlTags(enunciado);
        const alternativasSemHtml = alternativas.filter((item,index)=>index<this.state.quantidadeAlternativas).map(alternativa => ({ name: alternativa.name }));

        if (!alternativaCorreta || !nome.trim() || !text_enunciado_questao.trim() || alternativasSemHtml.some(alternativa => !this.removeHtmlTags(alternativa.name).trim().length)) {
            this.setState({ msgErro: 'Todos os campos são obrigatórios', loadingSave: false });
            window.scrollTo(0, 0);

            return;
        }

        const { token, match } = this.props;

        await this.saveQuestion({ nome, enunciado, text_enunciado_questao, alternativas, token, match });
    }

    convertLetterToIndex(letter) {
        return letter.charCodeAt(0) - 97;
    }


    async saveQuestion({ nome, enunciado, text_enunciado_questao, alternativas, token, match }) {
        const { id } = match.params;

        const requestBody = {
            nome,
            enunciado,
            text_enunciado_questao,
            alternativas: alternativas.map((alternativa, index) => ({
                name: alternativa.name,
                alternativa_correta: index === this.convertLetterToIndex(this.state.alternativaCorreta),
                questao_id: id,
                order: index
            })),
        };

        try {
            const response = await fetch(`${URL}api/conteudo/edit_question/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody),
            });

            let resp = await response.json();

            if (resp.message === "Unauthenticated.") {
                this.handleUnauthenticated();
                return;
            }

            if (resp.errors != null || resp.error != null) {
                let errors = Object.values(resp.errors);
                let erro = '';
                for (let i = 0; i < errors.length; i++) {
                  if (i != errors.length - 1)
                    erro += errors[i] + '\n';
                  else
                    erro += errors[i];
                }
                console.log(erro);
                this.setState({ msgErro: erro,loadingSave:false });
                window.scrollTo(0, 0);
              }
              else{
                this.setState({ loadingSave: false, redirect: true });
              }

        } catch (error) {
            this.setState({ msgErro: 'Erro ao editar a questão',loadingSave:false });
        }
    }

    handleChange = (field, value) => {
        this.setState({ [field]: value });
    };

    handleChangeEnunciado = (enunciado) => {
        this.setState({ enunciado: enunciado });

    };

    handleChangeQuantidadeAlternativas = (event) => {
        const quantidade = parseInt(event.target.value);
        const novasAlternativas = Array.from({ length: quantidade }, (_, index) => {
            if (this.state.alternativas[index]) {
                return this.state.alternativas[index];
            } else {
                return { nome: '', alternativa_correta: false, order: String.fromCharCode(97 + index) };
            }
        });

        this.setState({
            quantidadeAlternativas: quantidade,
            alternativas: novasAlternativas,
        });
    };
    handleChangeAlternativa = (index, value) => {
        const novasAlternativas = [...this.state.alternativas];
        novasAlternativas[index] = { ...novasAlternativas[index], name: value };
        this.setState({ alternativas: novasAlternativas }, () => {
        });
    };

    getAlternativaLabel = (index) => {
        return String.fromCharCode(97 + index) + ')';
    };


    handleSelectAlternativaCorreta = (event) => {
        this.setState({ alternativaCorreta: event.target.value });
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        this.salvar();
    };

    

    render() {
        const { loading, nome, enunciado, alternativas, redirect, path, msgErro, loadingSave, quantidadeAlternativas, alternativaCorreta } = this.state;
        const contentId = localStorage.getItem('idContent');

        if (redirect) {
            return <Redirect to={`${path}${contentId}?tab=1'`} />;
        }

        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                    { <ModalFormula setImage={(image,html)=>{
                                        let  img =document.createElement('img')
                                        img.src=image
                                        console.log(this.editor_enunciado)
                                        this.editor_select.selection.insertNode(img)
                                        // this.editor_enunciado.selection.insertHTML(html)
                                                    // this.setState({ enunciado:'<p>'+html+'</p>' })

                                }} showModal={this.state.showModalFormula} setShowModal={(e) => this.setState({ showModalFormula: e })} />}

                        <div className="card-body card-body-custom" id="card">
                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar Questão</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite uma questão do conteúdo {this.state.content.name}</p>
                            <br />

                            {msgErro && (
                                <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                                        <p style={{ marginBottom: 0 }}>{msgErro}</p>
                                    </div>
                                </div>
                            )}

                            {loading ? (
                                <div>
                                    <Skeleton height={40} style={{ marginBottom: '1rem' }} />
                                    <Skeleton height={40} style={{ marginBottom: '1rem' }} />
                                    <Skeleton height={40} style={{ marginBottom: '1rem' }} />
                                    <Skeleton height={40} style={{ marginBottom: '1rem' }} />
                                </div>
                            ) : (
                                <form className="position-relative" onSubmit={this.handleSubmit}>
                                    <div className="form-group flex fdcolumn flex1 margin-bottom">
                                    <Form.Label>Nome <Attention /></Form.Label>
                                    <input
                                            type="text"
                                            className="form-control input-custom"
                                            id="nome"
                                            placeholder="Nome da questão"
                                            value={nome}
                                            onChange={(e) => this.setState({ nome: e.target.value })}
                                            required
                                        />
                                    </div>

                                    <div className="form-group flex fdcolumn flex1 margin-bottom">
                                    <Form.Label>Tipo da Questão <Attention /></Form.Label>
                                    <select
                                        className="form-control"
                                        id="tipoQuestao"
                                        name="tipoQuestao"
                                        required
                                    >
                                        <option value="objetiva">Objetiva</option>
                                    </select>
                                </div>

                                    <Form.Group controlId="formEnunciado" className="mb-4">
                                        <Form.Label>Enunciado:</Form.Label>
                                        <JoditEditor
                                            value={enunciado}
                                            config={this.editorOptions}
                                            onBlur={(newContent) => this.handleChangeEnunciado(newContent)}
                                            onChange={newContent => { }}
                                        />
                                    </Form.Group>

                                    <div className="form-group flex fdcolumn flex1 margin-bottom">
                                    <Form.Label>Número de alternativas <Attention /></Form.Label>
                                    <select
                                            className="form-control"
                                            id="quantidadeAlternativas"
                                            value={quantidadeAlternativas}
                                            onChange={this.handleChangeQuantidadeAlternativas}
                                        >
                                            {[2, 3, 4, 5].map((number) => (
                                                <option key={number} value={number}>{number}</option>
                                            ))}
                                        </select>
                                    </div>

                                    {alternativas.filter((item,id)=>id<quantidadeAlternativas).map((alternativa, index) => (
                                        <div className="form-group flex fdcolumn flex1 margin-bottom" key={index}>
                                            <Form.Label>{`Alternativa ${String.fromCharCode(97 + index)})`} <Attention /></Form.Label>
                                            <JoditEditor
                                                ref={this.alternativasRefs[index]}
                                                value={alternativas[index] ? alternativas[index].name : ''}
                                                config={this.editorOptions}
                                                onBlur={newContent => this.handleChangeAlternativa(index, newContent)}
                                                onChange={newContent => { }}
                                            />
                                        </div>
                                    ))}

                                    <Form.Group controlId="formAlternativaCorreta" className="mb-4">
                                    <Form.Label>Qual a alternativa correta? <Attention /></Form.Label>

                                    <select
                                        className="form-control"
                                        id="alternativa_correta"
                                        value={alternativaCorreta}
                                        onChange={this.handleSelectAlternativaCorreta}
                                    >
                                        <option value="">Escolha a alternativa correta</option>
                                        {[...Array(quantidadeAlternativas)].map((_, index) => (
                                            <option key={index} value={String.fromCharCode(97 + index)}>
                                                {`${this.getAlternativaLabel(index)}`}
                                            </option>
                                        ))}
                                    </select>
                                    
                                    </Form.Group>

                                    <div className="w-100 d-flex justify-content-between mt-3">
                                        <DefaultButton
                                            bg="secondary"
                                            text="Voltar"
                                            icon={<RiArrowGoBackLine />}
                                            to={`/conteudo/show/${contentId}`}
                                            search='?tab=1'
                                        />
                                        <DefaultButton
                                            type="submit"
                                            bg="confirm"
                                            text="Salvar questão"
                                            loadingtext="Salvando questão"
                                            loading={loadingSave}
                                        />
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.AppReducer.user,
    token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(EditarQuestaoConteudo);
