import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import { API_URL, STORAGE_URL, URL } from '../../variables';
import { logout } from '../../actions/AppActions';
import JoditEditor from 'jodit-react';
import Attention from '../ReusableComponents/Attention';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ModalFormula from '../CategoriasQuestoes/ModalFormula';
import FormulaFx from '../../assets/images/formula-4.png';
// import 'jodit/build/jodit.min.css';
import Skeleton from '@material-ui/lab/Skeleton';


class CriarQuestaoConteudo extends Component {
    _isMounted = false;
    alternativasRefs = [];

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/conteudo/show/',
            nome: '',
            enunciado: '',
            alternativa_correta: '',
            loadingSave: false,
            msgErro: '',
            alternativas: Array(5).fill(''),
            quantidadeAlternativas: 4,
            loading:true,
            content:{},
            loading_image:false,
            showModalFormula:false
        };
        this.editorOptions = {
            height: 400,
                askBeforePasteHTML: false,
                askBeforePasteFromWord: false,
                defaultActionOnPaste: 'insert_only_text',
                defaultActionOnPasteFromWord: 'insert_only_text',
                pasteHTMLActionList: [
                    { value: 'insert_only_text', text: 'Insert only Text' }
                ],
                pasteFromWordActionList: [
                    { value: 'insert_only_text', text: 'Insert only Text' }
                ],
                readonly: this.state.loading_image,
                language: 'pt_br',
                toolbarButtonSize: 'large',
                removeButtons: ['video', 'fullsize', 'source', 'copyformat', 'about', 'link', 'classSpan'],
                toolbarAdaptive: false,
                extraButtons: [
            {
                name: 'equation',
          tooltip: 'Equações',
                iconURL: FormulaFx,
                exec:  (editor)=> {
                    this.setState({showModalFormula:true});
                    this.editor_select=editor;
                    // if (editor.selection.isCollapsed()) {
                    // 	editor.execCommand('selectall');
                    // }
                    // editor.execCommand('copy');
                    // Jodit.Alert('Text in your clipboard');
                    console.log('clicou')
                }
            }],
                // "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
                uploader: {
                    url: API_URL + 'faculdade/upload_image',  //your upload api url
                    insertImageAsBase64URI: false, //not inster base64
                    imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                    headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                    filesVariableName: function (t) {
                        return 'imagem';
                    }, //"files",
                    withCredentials: false,
                    pathVariableName: 'path',
                    //   format: 'json',
                    method: 'POST',
                    prepareData: (formdata) => {
                        console.log(formdata)
                        this.setState({ loading_image: true });
                        return formdata;
                    },
                    isSuccess: (e) => {
                        console.log(e);
    
                        return 'Upload realizado com sucesso';
                    },
                    getMessage: (e) => {
                        console.log(e);
    
                        return e;
                    },
                    process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                        let files = [];
                        console.log(resp);
                        files.unshift(STORAGE_URL + resp.imagem);
                        this.setState({ loading_image: false });
    
                        return {
                            files: files,
    
                        };
                    },
                    error: (elem, e) => {
                        // elem.j.e.fire('errorMessage', e.message, 'error', 4000);
    
                        console.log(e);
                        console.log(elem);
                        console.log('Bearer ' + localStorage.getItem('token'));
                    },
                    defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                        if (resp.files && resp.files.length) {
                            resp.files.forEach((filename, index) => { //edetor insertimg function
                                if (filename.includes('.mp4')) {
                                    this.selection.insertHTML(`<video controls height="500" width="500" src="${filename}"></video>`)
                                }
                                else if (filename.includes('.mp3')) {
                                    this.selection.insertHTML(`<audio controls src="${filename}"></audio>`)
                                }
                                else {
                                    this.selection.insertImage(filename);
                                }
                            });
                        }
    
                        // debugger;
    
                    },
                    defaultHandlerError: (elem, e) => {
                        // elem.j.e.fire('errorMessage', e.message);
                        console.log(e);
                        console.log(elem);
                        this.setState({ loading_image: false });
    
                    },
                    //   contentType: function (e) {
                    //     return (
    
                    //       'application/x-www-form-urlencoded; charset=UTF-8'
                    //     );
                    //   },
                },
        };

        this.alternativasRefs = Array.from({ length: 5 }, () => createRef());
        this.enunciadoRef = createRef();
    }

    handleUnauthenticated(){
        localStorage.removeItem('token'); localStorage.removeItem('user');
        this.props.logout();
    }
    
    getContent(id) {
        this.setState({ loading: true });

        fetch(`${URL}api/conteudo/get_content/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.props.token}`
            }
        })
            .then(async(response) => {
                
                return await response.json();
            })
            .then(data => {
                if (data.message === "Unauthenticated.") {
                    this.handleUnauthenticated();
                    return;
                }

                if (data.errors != null || data.error != null) {
                    this.setState({ redirect: true });
                    return;
                }


                this.setState({
                    content: data.data,
                    loading: false,
                });

            })
            .catch(error => {
                console.error('There was an error!', error);
                this.setState({ redirect: true });
            });
    }

    componentDidMount() {
        this._isMounted = true;
        this.getContent(this.props.match.params.id);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    removeHtmlTags = (str) => {
        if (!str) return '';
        return str.toString().replace(/<[^>]*>/g, '');
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({ loadingSave: true, msgErro: '' });

        const { nome, enunciado, alternativas, alternativa_correta } = this.state;
        const conteudo_id = this.props.match.params.id
        const text_enunciado_questao = this.removeHtmlTags(enunciado);

        const alternativasSemHtml = alternativas.filter((item,index)=>index<this.state.quantidadeAlternativas).map((alternativa, index) => ({
            name: alternativa,
            order: index,
            alternativa_correta: alternativa_correta === String.fromCharCode(97 + index)
        }));

        if (!alternativa_correta || !nome || !nome.trim().length || !text_enunciado_questao || !text_enunciado_questao.trim().length || alternativasSemHtml.some(alternativa => !this.removeHtmlTags(alternativa.name).trim().length)) {
            this.setState({ msgErro: 'Todos os campos são obrigatórios', loadingSave: false });
            window.scrollTo(0, 0);

            return;
        }

        try {
            const responseQuestao = await fetch(`${URL}api/conteudo/store_question`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${this.props.token}`,
                },
                body: JSON.stringify({ nome, enunciado, conteudo_id, text_enunciado_questao, user_id: this.props.user.id,alternativas:alternativasSemHtml })
            });
            console.log(responseQuestao)
            let resp = await responseQuestao.json();

            if (resp.message === "Unauthenticated.") {
                this.handleUnauthenticated();
                return;
            }

            if (resp.errors != null || resp.error != null) {
                let errors = Object.values(resp.errors);
                let erro = '';
                for (let i = 0; i < errors.length; i++) {
                  if (i != errors.length - 1)
                    erro += errors[i] + '\n';
                  else
                    erro += errors[i];
                }
                console.log(erro);
                this.setState({ msgErro: erro,loadingSave:false });
                window.scrollTo(0, 0);
              }
              else{
                this.setState({ loadingSave: false, redirect: true });
              }

            

        } catch (err) {
            console.error(err);
            if (this._isMounted) {
                this.setState({ msgErro: 'Erro ao pegar resposta do servidor' });
            }
        } finally {
            if (this._isMounted) {
                this.setState({ loadingSave: false });
            }
        }
    };

    handleChangeEnunciado = (value) => {
        this.setState({ enunciado: value });
    };

    handleChangeQuantidadeAlternativas = (event) => {
        const quantidade = parseInt(event.target.value);
        this.setState({ quantidadeAlternativas: quantidade,alternativa_correta:'' });
    };

    handleChangeAlternativa = (index, value) => {
        const novasAlternativas = [...this.state.alternativas];
        novasAlternativas[index] = value;
        this.setState({ alternativas: novasAlternativas });
    };

    handleSelectAlternativaCorreta = (event) => {
        const { value } = event.target;
        this.setState({ alternativa_correta: value }, () => {
        });
    };



    getAlternativaLabel = (index) => {
        return String.fromCharCode(97 + index) + ')';
    };

   

    render() {
        const { nome, loadingSave, msgErro, enunciado, alternativas, quantidadeAlternativas, alternativa_correta } = this.state;

        return (
            <div className="row">
                {this.state.redirect && <Redirect to={this.state.path + this.props.match.params.id+'?tab=1'} />}
                { <ModalFormula setImage={(image,html)=>{
                                        let  img =document.createElement('img')
                                        img.src=image
                                        console.log(this.editor_enunciado)
                                        this.editor_select.selection.insertNode(img)
                                        // this.editor_enunciado.selection.insertHTML(html)
                                                    // this.setState({ enunciado:'<p>'+html+'</p>' })

                                }} showModal={this.state.showModalFormula} setShowModal={(e) => this.setState({ showModalFormula: e })} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body card-body-custom" id="card">
                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Cadastrar Questão</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Cadastre as questões do conteúdo {this.state.content.name}</p>
                            <br />
                            {msgErro && (
                                <div style={{ display: 'flex', marginBottom: 15, width: '100%' }}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>
                                        <p style={{ marginBottom: 0 }}>{msgErro}</p>
                                    </div>
                                </div>
                            )}
{this.state.loading ? (
                                <div>
                                    <Skeleton height={40} style={{ marginBottom: '1rem' }} />
                                    <Skeleton height={40} style={{ marginBottom: '1rem' }} />
                                    <Skeleton height={40} style={{ marginBottom: '1rem' }} />
                                    <Skeleton height={40} style={{ marginBottom: '1rem' }} />
                                </div>
                            ) : (
                            <form className="position-relative" onSubmit={this.handleSubmit}>
                                <div className="form-group flex fdcolumn flex1 margin-bottom">
                                <Form.Label>Nome <Attention /></Form.Label>
                                    <input
                                        type="text"
                                        className="form-control input-custom"
                                        id="nome"
                                        placeholder="Nome da questão"
                                        value={nome}
                                        onChange={(e) => this.setState({ nome: e.target.value })}
                                        required
                                    />
                                </div>

                                <div className="form-group flex fdcolumn flex1 margin-bottom">
                                    <Form.Label>Tipo da Questão <Attention /></Form.Label>
                                    <select
                                        className="form-control"
                                        id="tipoQuestao"
                                        name="tipoQuestao"
                                        required
                                    >
                                        <option value="objetiva">Objetiva</option>
                                    </select>
                                </div>

                                <div className="form-group flex fdcolumn flex1 margin-bottom me-3">
                                <Form.Label>Enunciado <Attention /></Form.Label>
                                    <JoditEditor
                                        ref={this.enunciadoRef}
                                        value={enunciado}
                                        config={this.editorOptions}
                                        onBlur={newContent => this.handleChangeEnunciado(newContent)}
                                        onChange={newContent => { }}
                                    />
                                </div>

                                <div className="form-group flex fdcolumn flex1 margin-bottom">
                                <Form.Label>Número de alternativas <Attention /></Form.Label>
                                    <select
                                        className="form-control"
                                        id="quantidadeAlternativas"
                                        value={quantidadeAlternativas}
                                        onChange={this.handleChangeQuantidadeAlternativas}
                                    >
                                        {[2, 3, 4, 5].map((number) => (
                                            <option key={number} value={number}>{number}</option>
                                        ))}
                                    </select>
                                </div>

                                {alternativas.filter((item,id)=>id<this.state.quantidadeAlternativas).map((alternativa, index) => (
                                    <div className="form-group flex fdcolumn flex1 margin-bottom" key={index}>
                                        <Form.Label>{`Alternativa ${String.fromCharCode(97 + index)})`} <Attention /></Form.Label>
                                        <JoditEditor
                                            ref={this.alternativasRefs[index]}
                                            value={alternativa}
                                            config={this.editorOptions}
                                            onBlur={newContent => this.handleChangeAlternativa(index, newContent)}
                                            onChange={newContent => { }}
                                        />
                                    </div>
                                ))}

                                <div className="form-group flex fdcolumn flex1 margin-bottom">
                                <Form.Label>Qual a alternativa correta? <Attention /></Form.Label>
                                <select
                                        className="form-control"
                                        id="alternativa_correta"
                                        value={alternativa_correta}
                                        onChange={this.handleSelectAlternativaCorreta}
                                    >
                                        <option value="">Escolha a alternativa correta</option>
                                        {[...Array(quantidadeAlternativas)].map((_, index) => (
                                            <option key={index} value={String.fromCharCode(97 + index)}>
                                                {`${this.getAlternativaLabel(index)}`}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="w-100 d-flex justify-content-between mt-3">
                                    <DefaultButton
                                        bg="secondary"
                                        text="Voltar"
                                        icon={<RiArrowGoBackLine />}
                                        to={"/conteudo/show/" + localStorage.getItem('idContent')}
                                        search='?tab=1'
                                    />
                                    <DefaultButton
                                        type="submit"
                                        bg="confirm"
                                        text="Salvar questão"
                                        disabled={loadingSave}
                                        loadingtext="Salvando questão"
                                        loading={loadingSave}
                                    />
                                </div>
                            </form>)}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.AppReducer.user,
    token: state.AppReducer.token,
});

export default connect(mapStateToProps, { logout })(CriarQuestaoConteudo);
