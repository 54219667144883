import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { BsFillEyeFill } from "react-icons/bs";
import { Pagination } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from 'react-bootstrap';
import { FaListAlt } from 'react-icons/fa';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import SweetAlert from 'react-bootstrap-sweetalert';
import Search from '../ReusableComponents/Search';
import { URL } from '../../variables';

class Treinamentos extends Component {
    state = {
        redirect: false,
        trainings: [],
        loading: false,
        loading_search: true,
        showDeleteConfirmation: false,
        trainingToDelete: '',
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 0,
            per_page: 30,
        },
        errorMsg: '',
        search: '',
        isMounted: false,
    };

    componentDidMount() {
        this.setState({ isMounted: true });
        this.getTrainings(this.state.pagination.current_page);
    }

    componentWillUnmount() {
        this.setState({ isMounted: false });
    }

    getTrainings = async (page, search = '', busca = true) => {
        if (busca && !this.state.loading_search) {
            this.setState({ loading_search: true });
        } else if (!busca && !this.state.loading) {
            this.setState({ loading: true });
        }

        try {
            const response = await fetch(`${URL}api/treinamento/get_trainings?page=${page}&search=${search}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${this.props.token}`,
                }
            });

            if (!response.ok) {
                throw new Error('Erro ao buscar treinamentos');
            }
            const data = await response.json();

            if (this.state.isMounted) {
                this.setState({
                    trainings: data.data || [],
                    pagination: {
                        current_page: data.pagination.current_page,
                        last_page: data.pagination.last_page,
                        total: data.pagination.total,
                        per_page: data.pagination.per_page,
                    },
                });
            }

        } catch (error) {
            console.error(error.message || 'Erro ao buscar treinamentos');
            if (this.state.isMounted) {
                this.setState({ errorMsg: error.message || 'Erro ao buscar treinamentos', redirect: true });
            }

        } finally {
            if (this.state.isMounted) {
                this.setState({ loading_search: false, loading: false });
            }
        }
    };

    handleDeleteTraining = async (trainingId) => {
        try {
            const response = await fetch(`${URL}api/treinamento/delete_training/${trainingId}`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Authorization': `Bearer ${this.props.token}`,
                }
            });

            if (!response.ok) {
                throw new Error('Erro ao excluir treinamento');
            }
            await this.getTrainings(this.state.pagination.current_page, this.state.search, false);

        } catch (error) {
            console.error(error.message || 'Erro ao excluir treinamento');
            if (this.state.isMounted) {
                this.setState({ errorMsg: error.message || 'Erro ao excluir treinamento' });
            }

        } finally {
            if (this.state.isMounted) {
                this.setState({ showDeleteConfirmation: false });
            }
        }
    };

    handleSearchChange = (value) => {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.setState({
                search: value,
                pagination: { ...this.state.pagination, current_page: 1 },
                loading_search: true,
            });
            this.getTrainings(1, value);
        }, 500);
    };

    handlePageChange = (event, value) => {
        this.setState({ pagination: { ...this.state.pagination, current_page: value } });
        this.getTrainings(value, this.state.search, false);
    };

    formatarData = (dataString) => {
        const data = new Date(dataString);
        const dia = String(data.getDate()).padStart(2, '0');
        const mes = String(data.getMonth() + 1).padStart(2, '0');
        const ano = data.getFullYear();
        const horas = String(data.getHours()).padStart(2, '0');
        const minutos = String(data.getMinutes()).padStart(2, '0');
        const segundos = String(data.getSeconds()).padStart(2, '0');

        return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
    }

    render() {
        const { redirect, loading, showDeleteConfirmation, trainingToDelete, errorMsg, pagination, loading_search } = this.state;

        if (redirect) {
            return <Redirect to="/" />;
        }

        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">
                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Treinamentos</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados todos os treinamentos do seu sistema</p>
                            <br />
                            <div className="row">
                                <div className="col-12">
                                    <div className="flex jcsb flex-wrap mb-4">
                                        <Search placeholder="Pesquisar pelo nome do treinamento" ValueChange={this.handleSearchChange} />
                                        <Link to="/treinamentos/register/" style={{ textDecoration: "none" }}>
                                            <Button variant="success" className="flex align-center jcc">
                                                <FaListAlt style={{ marginRight: 10 }} />
                                                <p style={{ margin: 0 }}>Adicionar novo treinamento</p>
                                            </Button>
                                        </Link>
                                    </div>
                                    <div className="table-rep-plugin">
                                        {loading ? (
                                            <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                                <CircularProgress />
                                            </div>
                                        ) : (
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <table id="tech-companies-1" className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Nome</th>
                                                            <th>Número de Módulos</th>
                                                            <th>Data de criação</th>
                                                            <th>Ações</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {!loading_search && this.state.trainings.map((training, index) => (
                                                            <tr key={index}>
                                                                <td>{training.nome}</td>
                                                                <td>{training.numero_modulos}</td>

                                                                <td>{this.formatarData(training.created_at)}</td>


                                                                <td>
                                                                    <Link to={`/treinamento/show/${training.id}`} onClick={(e) => e.stopPropagation()}>
                                                                        <span className="inline-flex align-center jcc bg-warning icon" style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px" }} title="Gerenciar">
                                                                            <BsFillEyeFill size={17} color="white" />
                                                                        </span>
                                                                    </Link>
                                                                    <Link to={`/treinamento/edit/${training.id}`} style={{ cursor: 'pointer' }}>
                                                                        <span
                                                                            className="inline-flex align-center jcc bg-info icon"
                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px", }}
                                                                            data-toggle="tooltip"
                                                                            title="Editar treinamento"
                                                                        >
                                                                            <MdModeEdit color="white" size={17} />
                                                                        </span>
                                                                    </Link>
                                                                    <span
                                                                        onClick={() => this.setState({ showDeleteConfirmation: true, trainingToDelete: training.id })}
                                                                        className="inline-flex align-center jcc bg-danger icon"
                                                                        style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                        title="Deletar treinamento"
                                                                    >
                                                                        <MdDelete size={17} color="white" />
                                                                    </span>
                                                                </td>
                                                            </tr>

                                                        ))}

                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                    </div>
                                    {loading_search && (
                                        <div className="row" style={{ margin: 0 }}>
                                            <div className="col-12 d-flex justify-content-center align-items-center">
                                                <CircularProgress />
                                            </div>
                                        </div>
                                    )}
                                    {!loading_search && this.state.trainings.length === 0 && (
                                        <div className="row w-100">
                                            <div className="col-12">
                                                <p style={{ textAlign: 'center' }}>Nenhum treinamento cadastrado</p>
                                            </div>
                                        </div>
                                    )}

                                    <Pagination
                                        className={pagination.last_page === 1 ? "hide" : ""}
                                        style={{ display: "flex", justifyContent: "flex-end" }}
                                        count={pagination.last_page}
                                        page={pagination.current_page}
                                        onChange={this.handlePageChange}
                                    />
                                    <SweetAlert
                                        warning
                                        title={"Atenção"}
                                        show={showDeleteConfirmation}
                                        onConfirm={() => this.handleDeleteTraining(trainingToDelete)}
                                        onCancel={() => this.setState({ showDeleteConfirmation: false })}
                                        confirmBtnText="Sim, desejo apagar"
                                        cancelBtnText="Cancelar"
                                        confirmBtnBsStyle="success"
                                        cancelBtnBsStyle="danger"
                                        showCancel={true}
                                    >
                                        {errorMsg ? errorMsg : "Ao deletar este treinamento, ele não estará mais disponível no sistema"}
                                    </SweetAlert>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    token: state.AppReducer.token,
});

export default connect(mapStateToProps)(Treinamentos);