// Library Imports
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { Dropdown, Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RiArrowGoBackLine, RiCloseCircleLine } from "react-icons/ri";

// Components Imports
import DefaultButton from "../ReusableComponents/DefaultButton";
import Attention from "../ReusableComponents/Attention";

// Utils Imports
import { get, put } from "../../Services/api";
import { MdDelete } from "react-icons/md";

const EditarTreinamento = ({ token, match, history }) => {
  const [formData, setFormData] = useState({
    redirect: false,
    loading: true,
    nome: "",
    descricao: "",
    modulos: [],
    selectedModulos: [],
    modulo_ids: [],
    loadingSave: false,
    msgErro: "",
    dropdownOpen: false,
  });

  const [show_autocomplete, setShowAutoComplete] = useState(false);
  const [content_text, setContentText] = useState('');
  const timeout_ = useRef();
  const candidato_timeout = useRef();
  const [loading_search, setLoadingSearch] = useState(false);


  const getModulos = async (search = '') => {
    setLoadingSearch(true);
    try {
      const data = await get("api/modulo/get_modules?search=" + search, token);

      if (data.errors) {
        let errors = Object.values(data.errors);
        let errorMsg = errors.join("\n");
        console.log(errorMsg);
      } else {
        setFormData((prevState) => ({
          ...prevState,
          modulos: data.data,
          loading: false,
        }));
      }
    } catch (error) {
      if (error.name !== "AbortError") {
        console.log(error.message || "Erro ao buscar módulos");
        setFormData((prevState) => ({
          ...prevState,
          msgErro: error.message || "Erro ao buscar módulos",
          loading: false,
        }));
      }
    }
    finally {
      setLoadingSearch(false);
    }
  };


  const fetchData = async () => {
    const { id } = match.params;

    try {
      const trainingResponse = await get(
        `api/treinamento/get_training/${id}`,
        token
      );

      const { nome, descricao, modulos } = trainingResponse.data;


      setFormData({
        nome,
        descricao,
        modulos,
        selectedModulos: modulos,
        loading: false,
        redirect: false,
        loadingSave: false,
        msgErro: "",
      });
    } catch (error) {
      console.error("Erro:", error);
      setFormData((prevState) => ({
        ...prevState,
        redirect: true,
        loading: false,
      }));
    }
  };

  useEffect(() => {


    fetchData();
  }, [match.params, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSelectChange = (content) => {
    setFormData((prevState) => {
      if (prevState.selectedModulos.filter((item) => item.id == content.id).length == 0) {
        prevState.selectedModulos.push(content)
      }
      return {
        ...prevState,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { id } = match.params;
    const { nome, descricao } = formData;
    const modulo_ids = formData.selectedModulos.map((modulo) => modulo.id);
    setFormData((prevState) => ({
      ...prevState,
      loadingSave: true,
      msgErro: "",
    }));

    try {
      let resp = await put(
        `api/treinamento/edit_training/${id}`,
        { nome, descricao, modulo_ids },
        token
      );
      if (resp.status == false) {
        setFormData((prevState) => ({
          ...prevState,
          msgErro: resp.message,
          loadingSave: false
        }));
      }
      else {
        history.push("/treinamentos");
      }
    } catch (error) {
      setFormData((prevState) => ({
        ...prevState,
        msgErro: error.message || "Erro ao editar treinamento",
        loadingSave: false,
      }));
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const modulos = Array.from(formData.modulos);
    const [reorderedItem] = modulos.splice(result.source.index, 1);
    modulos.splice(result.destination.index, 0, reorderedItem);

    setFormData((prevState) => ({ ...prevState, modulos }));
    await saveOrder(modulos);
  };

  const saveOrder = async (modulos) => {
    const { id } = match.params;
    try {
      await put(
        `api/treinamento/edit_training/${id}`,
        {
          nome: formData.nome,
          descricao: formData.descricao,
          modulo_ids: modulos.map((modulo) => modulo.id),
        },
        token
      );
    } catch (error) {
      console.error(error.message || "Erro ao salvar a nova ordem");
      setFormData((prevState) => ({
        ...prevState,
        msgErro: error.message || "Erro ao salvar a nova ordem",
      }));
    }
  };

  const toggleDropdown = () => {
    setFormData((prevState) => ({
      ...prevState,
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  const handleRemoveModulo = (id) => {
    setFormData((prevState) => {

      const selectedModulos = prevState.selectedModulos.filter(
        (modulo) => modulo.id !== id
      );

      return {
        ...prevState,
        selectedModulos,
      };
    });
  };

  if (formData.redirect) {
    return <Redirect to="/treinamentos" />;
  }

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-body" id="card" style={{ overflow: "visible" }}>
            <h4
              className="mt-0 header-title"
              style={{
                fontSize: "1.5rem",
                color: "black",
                marginBottom: "1rem",
              }}
            >
              Editar Treinamento
            </h4>
            <p
              style={{
                fontSize: "1rem",
                color: "black",
                marginBottom: "1.5rem",
              }}
            >
              Edite o treinamento do seu sistema
            </p>
            {formData.msgErro && (
              <div
                style={{
                  display: "flex",
                  marginBottom: "1.5rem",
                  width: "100%",
                }}
              >
                <div
                  className="alert alert-danger alert-dismissible fade show mb-0 w-100"
                  role="alert"
                >
                  <button
                    type="button"
                    className="btn-close"
                    onClick={() =>
                      setFormData((prevState) => ({
                        ...prevState,
                        msgErro: "",
                      }))
                    }
                  ></button>
                  <p style={{ marginBottom: 0 }}>{formData.msgErro}</p>
                </div>
              </div>
            )}
            {!formData.loading ? (
              <form onSubmit={handleSubmit}>
                <div className="form-group" style={{ marginBottom: "1.5rem" }}>
                  <Form.Label>Nome <Attention /></Form.Label>

                  <input
                    type="text"
                    className="form-control"
                    id="nome"
                    name="nome"
                    value={formData.nome}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group" style={{ marginBottom: "1.5rem" }}>
                  <Form.Label>Descrição <Attention /></Form.Label>

                  <input
                    type="text"
                    className="form-control"
                    id="descricao"
                    name="descricao"
                    value={formData.descricao}
                    onChange={handleChange}
                  />
                </div>
                {<InputGroup>


                  <div className="flex fdcolumn flex1 margin-bottom">
                    <Form.Label>Busque um ou mais módulos que deseja vincular </Form.Label>
                    <Input
                      placeholder="Pesquise pelo nome do conteúdo"
                      value={content_text}
                      // onChange={(e) => this.setState({ candidato_enunciado: e.target.value })}
                      onBlur={() => {
                        timeout_.current = setTimeout(() => {
                          setShowAutoComplete(false)
                        }, 500)
                      }}
                      onFocus={() => {
                        clearTimeout(timeout_.current);
                        setShowAutoComplete(true)
                      }}
                      onChange={(e) => {
                        clearTimeout(candidato_timeout.current);
                        candidato_timeout.current = setTimeout(() => {
                          getModulos(e.target.value);
                        }, 1000);
                        setContentText(e.target.value)
                      }}
                    // title="Coloque o nome completo do usuário"
                    />
                    {show_autocomplete == true && content_text != '' && <div className="overflow-auto" style={{ width: '100%', position: 'absolute', zIndex: 20, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', maxHeight: '50vh', top: 70 }}>
                      {loading_search == false && formData.modulos.map((item, id) => (
                        <div key={id} onClick={() => handleSelectChange(item)} className="opcao_autocomplete">
                          <b>{item.name}</b> - {item.description}
                        </div>
                      ))}
                      {loading_search == false && formData.modulos.length == 0 &&
                        <div className="opcao_autocomplete">
                          Nenhum módulo encontrado com este termo
                        </div>
                      }
                      {loading_search == true &&
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                          <div className="spinner-border text-primary" role="status"  >
                          </div></div>}
                    </div>}
                  </div>
                </InputGroup>}

                <InputGroup>

                  <div className="flex fdcolumn flex1 margin-bottom">
                    <Form.Label>Módulos vinculados <Attention /></Form.Label>

                    <div className="table-rep-plugin">
                      <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId="conteudos">
                            {(provided) => (
                              <table id="tech-companies-1" className="table table-striped"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                              >
                                <thead>
                                  <tr>
                                    <th>Nome</th>
                                    <th>Descrição</th>
                                    <th data-priority="3">Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {formData.selectedModulos.map((item, id) => (
                                    <Draggable key={item.id.toString()} draggableId={item.id.toString()} index={id}>
                                      {(provided) => (
                                        <tr ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}>
                                          <td>{item.name}</td>
                                          <td>{item.description}</td>

                                          {/* <td><img style={{ height: 60, width: 60, objectFit: 'contain' }} src={STORAGE_URL + STORAGE_URL + item.arquivo} /></td> */}

                                          <td>
                                            {/* <span
                                                        onClick={() => {
                                                            this.setState({ candidatoSelect: item, showModal: true });
                                                        }}
                                                        className="inline-flex align-center jcc bg-warning icon"
                                                        style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                        title="Gerenciar aula"
                                                    >
                                                        <BsFillEyeFill size={17} color="white" />
                                                    </span> */}
                                            <span
                                              onClick={(e) => {
                                                handleRemoveModulo(item.id)

                                              }}
                                              className="inline-flex align-center jcc bg-danger icon"
                                              style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                              title="Desvincular candidato"
                                            >
                                              <MdDelete size={17} color="white" />
                                            </span>
                                          </td>

                                        </tr>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </tbody>
                              </table>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    </div>
                    {formData.selectedModulos.length == 0 && <div className="row w-100">
                      <div className="col-12">
                        <p style={{ textAlign: 'center' }}>Nenhum módulo selecionado</p>

                      </div>
                    </div>}
                  </div>

                </InputGroup>
                <div className="w-100 d-flex justify-content-between mt-3">
                  <DefaultButton
                    bg="secondary"
                    text="Voltar"
                    icon={<RiArrowGoBackLine />}
                    to="/treinamentos"
                  />
                  <DefaultButton
                    type="submit"
                    bg="confirm"
                    text="Salvar treinamento"
                    disabled={formData.loadingSave}
                    loadingtext="Salvando treinamento"
                    loading={formData.loadingSave}
                  />
                </div>
              </form>
            ) : (
              <div>
                <Skeleton height={40} style={{ marginBottom: "1rem" }} />
                <Skeleton height={40} style={{ marginBottom: "1rem" }} />
                <Skeleton height={40} style={{ marginBottom: "1rem" }} />
                <Skeleton height={40} style={{ marginBottom: "1rem" }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state.AppReducer.token,
});

export default connect(mapStateToProps)(EditarTreinamento);
