import { useCallback, useEffect, useState } from "react"
import { CircularProgress, Snackbar, Tab, Tabs } from "@material-ui/core";
import { useDispatch } from "react-redux";
import moment from "moment";
import TextReadMore from "../../../ReusableComponents/TextReadMore";
import { logout } from "../../../../actions/AppActions";
import Request from "../../../../Request";
import LogsDash from "./LogsDash";
import { FaIntercom, FaListAlt, FaWindowClose } from "react-icons/fa";
import {  Button } from "react-bootstrap";
import SalasOnline from "./SalasOnline";
import CandidatosSaidas from "../../../CandidatosVinculados/CandidatosSaidas";
import { MdEmojiObjects, MdFace, MdFaceRetouchingNatural, MdInfo, MdOutlinePerson } from "react-icons/md";
import AnalisesCandidatos from "../../../AnalisesCandidatos/AnalisesCandidatos";
import AnalisesCandidatosObjetos from "../../../AnalisesCandidatosObjetos/AnalisesCandidatosObjetos";
import AnalisesMovimentoFacial from "../../../AnalisesMovimentoFacial/AnalisesMovimentoFacial";
import CandidatosConfiabilidade from "../../../CandidatosVinculados/CandidatosConfiabilidade";

const ProcessDashboard = props => {
    const [loading,setLoading] = useState(true);
    const [processo,setProcesso] = useState({});
    const dispatch = useDispatch();

    const [selectTab,setSelectTab] = useState(0);

    const get_processo = useCallback(async(processo_id)=>{
        setLoading(true);
        let response = await Request.Get(`faculdade/dashboard/processo/${processo_id}`);
        console.log(response);
        if (response === undefined) return;
        if (response['message'] == 'Unauthenticated.') {
            dispatch(logout());
            return;
        }
        setProcesso(response.processo[0])
        setLoading(false);
        return response;
    },[props.processo_id]);

    useEffect(()=>{
        get_processo(props.processo_id);
    },[]);



    return (
        <>
            {loading==false && <div>
            <p><b style={{fontSize:20}}>{processo.name}</b></p>
            
            <TextReadMore title="Descição: " element="div" limit={1000} style={{ width: "100%", marginBottom: 10, whiteSpace: 'pre' }}>
                {processo.descricao}
            </TextReadMore>
            <TextReadMore title="Início: " element="div" limit={1000} style={{ width: "100%", marginBottom: 10, whiteSpace: 'pre' }}>
                {moment(processo.inicio).format('DD/MM/YYYY HH:mm')}
            </TextReadMore>
            <TextReadMore title="Encerramento: " element="div" limit={1000} style={{ width: "100%", marginBottom: 10, whiteSpace: 'pre' }}>
                {moment(processo.fim).format('DD/MM/YYYY HH:mm')}
            </TextReadMore>

            <b style={{ marginRight: "2px" }}>Status: {moment().format('YYYY-MM-DD HH:mm') < moment(processo.fim).format('YYYY-MM-DD HH:mm') && moment().format('YYYY-MM-DD HH:mm') >= moment(processo.inicio).format('YYYY-MM-DD HH:mm') ? <span style={{ color: 'green', fontWeight: 'normal' }}>Andamento</span> : (moment().format('YYYY-MM-DD HH:mm') >= moment(processo.fim).format('YYYY-MM-DD HH:mm') ? <span style={{ fontWeight: 'normal', color: 'green' }}>Finalizado</span> : <span style={{ fontWeight: 'normal', color: 'darkgoldenrod' }}>Não iniciado</span>)}</b>
            {/* <hr /> */}
            <p style={{marginTop:10}}><b>Opções de relatório:</b></p>
            <div className="row" style={{margin:0, marginBottom:20}}>
                <Button style={{width:'auto',marginRight:10,marginTop:5}}  onClick={(e)=>{
                    e.preventDefault();
                    setSelectTab(0);
                }} variant={selectTab==0? "warning":'secondary'} size="sm" className="flex align-center jcc"><FaListAlt style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Logs gerais</p></Button>

<Button style={{width:'auto',marginRight:10,marginTop:5}}  onClick={(e)=>{
                    e.preventDefault();
                    setSelectTab(1);
                }} variant={selectTab==1? "warning":'secondary'} size="sm" className="flex align-center jcc"><FaIntercom style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Candidatos online</p></Button>

<Button style={{width:'auto',marginRight:10,marginTop:5}}  onClick={(e)=>{
                    e.preventDefault();
                    setSelectTab(2);
                }} variant={selectTab==2? "warning":'secondary'} size="sm" className="flex align-center jcc"><FaWindowClose style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Saídas de tela</p></Button>

<Button style={{width:'auto',marginRight:10,marginTop:5}}  onClick={(e)=>{
                    e.preventDefault();
                    setSelectTab(3);
                }} variant={selectTab==3? "warning":'secondary'} size="sm" className="flex align-center jcc"><MdFace style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Análises faciais</p></Button>


<Button style={{width:'auto',marginRight:10,marginTop:5}}  onClick={(e)=>{
                    e.preventDefault();
                    setSelectTab(4);
                }} variant={selectTab==4? "warning":'secondary'} size="sm" className="flex align-center jcc"><MdEmojiObjects style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Análises objetos</p></Button>

<Button style={{width:'auto',marginRight:10,marginTop:5}}  onClick={(e)=>{
                    e.preventDefault();
                    setSelectTab(5);
                }} variant={selectTab==5? "warning":'secondary'} size="sm" className="flex align-center jcc"><MdFaceRetouchingNatural style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Análises Movimento Facial</p></Button>
                
                <Button style={{width:'auto',marginRight:10,marginTop:5}}  onClick={(e)=>{
                    e.preventDefault();
                    setSelectTab(6);
                }} variant={selectTab==6? "warning":'secondary'} size="sm" className="flex align-center jcc"><MdInfo style={{ marginRight: 10 }} /> <p style={{ margin: 0 }}>Confiabilidade</p></Button>
                
                {/* <p style={{ marginBottom: 10 }}><b style={{ marginRight: "2px" }}>Total fiscais: <span style={{ fontWeight: 'normal' }}>{processo.total_fiscais}</span></b></p>
                                <p style={{ marginBottom: 10 }}><b style={{ marginRight: "2px" }}>Total corretores: <span style={{ fontWeight: 'normal' }}>{processo.total_corretores}</span></b></p>

                                <p style={{ marginBottom: 10 }}><b style={{ marginRight: "2px" }}>Total candidatos: <span style={{ fontWeight: 'normal' }}>{processo.total_alunos}</span></b></p>

                                <p style={{ marginBottom: 10 }}><b style={{ marginRight: "2px" }}>Candidatos que fizeram login: <span style={{ fontWeight: 'normal' }}>{processo.alunos_login}</span></b></p>
                                <p style={{ marginBottom: 10 }}><b style={{ marginRight: "2px" }}>Candidatos que acessaram a tela de termos: <span style={{ fontWeight: 'normal' }}>{processo.tela_termos}</span></b></p>
                                <p style={{ marginBottom: 10 }}><b style={{ marginRight: "2px" }}>Candidatos que acessaram a tela de avaliação não iniciada: <span style={{ fontWeight: 'normal' }}>{processo.tela_avaliacao_nao_iniciada}</span></b></p>

                                <p style={{ marginBottom: 10 }}><b style={{ marginRight: "2px" }}>Candidatos que acessaram a tela de aceite de regras: <span style={{ fontWeight: 'normal' }}>{processo.entraram_alunos_aceite}</span></b></p>
                                <p style={{ marginBottom: 10 }}><b style={{ marginRight: "2px" }}>Candidatos que acessaram a tela de avaliação em andamento: <span style={{ fontWeight: 'normal' }}>{processo.entraram_alunos_prova}</span></b></p>
                                <p style={{ marginBottom: 10 }}><b style={{ marginRight: "2px" }}>Candidatos que acessaram a tela de avaliação finalizada: <span style={{ fontWeight: 'normal' }}>{processo.tela_avaliacao_finalizada}</span></b></p>

                                <p style={{ marginBottom: 10 }}><b style={{ marginRight: "2px" }}>Candidatos ausentes: <span style={{ fontWeight: 'normal' }}>{processo.ausentes_alunos}</span></b></p>
                                <p style={{ marginBottom: 10 }}><b style={{ marginRight: "2px" }}>Candidatos que acessaram e não finalizaram: <span style={{ fontWeight: 'normal' }}>{processo.nao_finalizaram_alunos}</span></b></p>
                                <p style={{ marginBottom: 10 }}><b style={{ marginRight: "2px" }}>Candidatos que acessaram e finalizaram: <span style={{ fontWeight: 'normal' }}>{processo.finalizaram_alunos}</span></b></p> */}
                





            </div>
            <hr/>
            {selectTab==0 && <LogsDash processo={processo} />}
            {selectTab==1 && <SalasOnline processo={processo} />}
            {selectTab==2 && <CandidatosSaidas processo_id={processo.id} processo_name={processo.name} />}
            {selectTab==3 && <AnalisesCandidatos processo_id={processo.id} processo_name={processo.name} />}
            {selectTab==4 && <AnalisesCandidatosObjetos processo_id={processo.id} processo_name={processo.name} />}

            {selectTab==5 && <AnalisesMovimentoFacial processo_id={processo.id} processo_name={processo.name} />}
            {selectTab==6 && <CandidatosConfiabilidade processo_id={processo.id} processo_name={processo.name} />}





            {/* <hr /> */}

            {/* <p style={{ marginTop: 10, marginBottom: 0 }}><b >Salas:</b></p> */}

            </div>}
            {loading == true && <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                <CircularProgress />
            </div></div>}

        </>
    )


}

export default ProcessDashboard;
