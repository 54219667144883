import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../actions/AppActions';
import { URL } from '../../variables';
import DefaultButton from '../ReusableComponents/DefaultButton';
import { RiArrowGoBackLine } from 'react-icons/ri';
import CircularProgress from '@material-ui/core/CircularProgress';
import SweetAlert from 'react-bootstrap-sweetalert';
import Search from '../ReusableComponents/Search';
import { FaListAlt } from 'react-icons/fa';
import { BsFillEyeFill } from "react-icons/bs";
import { Pagination } from '@material-ui/lab';
import { MdModeEdit, MdDelete } from 'react-icons/md';
import { Button, Container, Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';
import TabPanel from '../ReusableComponents/TabPanel';
import { Tab, Tabs } from '@material-ui/core';
import TextReadMore from '../ReusableComponents/TextReadMore';
import moment from 'moment';

class ShowConteudo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            name: '',
            file: '',
            thumbnail: '',
            questoesAll: [],
            loading: true,
            showDeleteConfirmation: false,
            questionToDelete: null,
            categoria: null,
            show_warning: false,
            show_warning_anular: false,
            questaoStatus: null,
            loading_search: true,
            search: '',
            errorMsg: '',
            showModal: false,
            alternativas: [],
            alternativasPorQuestao: {},
            currentQuestion: null,
            type: '',
            activeTab: 0,
            pagination: {
                page: 1,
                last_page: 1,
            },
            has_questions: false,
        };
    }

    openModal = (questao) => {
        this.setState({ showModal: true, currentQuestion: questao });
    };

    closeModal = () => {
        this.setState({ showModal: false, currentQuestion: null });
    };

    componentDidMount() {
        const { id } = this.props.match.params;
        let tab = new URLSearchParams(this.props.location.search).get('tab');
        if (tab !== undefined && tab !== null) this.setState({ activeTab: parseInt(tab) });
        else if (tab === null) this.setState({ activeTab: 0 });
        this.getContent(id);
        this.getQuestions(id, this.state.pagination.page);
        localStorage.setItem('idContent', id)
    }

    getContent(id) {
        this.setState({ loading: true });

        fetch(`${URL}api/conteudo/get_content/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.props.token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                if (data.message === "Unauthenticated.") {
                    this.handleUnauthenticated();
                    return;
                }

                if (data.errors != null || data.error != null) {
                    this.setState({ redirect: true });
                    return;
                }

                const { name, file, thumbnail, has_questions, type, created_at } = data.data;

                this.setState({
                    name: name,
                    file: file,
                    type: type,
                    thumbnail: thumbnail,
                    has_questions: has_questions,
                    loading: false,
                    created_at
                });


            })
            .catch(error => {
                console.error('There was an error!', error);
                this.setState({ redirect: true });
            });
    }

    handleUnauthenticated() {
        localStorage.removeItem('token'); localStorage.removeItem('user');
        this.props.logout();
    }

    getQuestions(id, page, search = '', busca = true) {
        if (busca && !this.state.loading_search) {
            this.setState({ loading_search: true });
        } else if (!busca && !this.state.loading_screen) {
            this.setState({ loading_screen: true });
        }

        fetch(`${URL}api/conteudo/get_questions/${id}?page=${page}&search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${this.props.token}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                this.setState({ loading: false });

                if (data.message === "Unauthenticated.") {
                    this.handleUnauthenticated();
                    return;
                }

                if (data.errors != null || data.error != null) {
                    this.setState({ redirect: true });
                    return;
                }

                const responseData = data.data;

                this.setState({
                    questoesAll: responseData,
                });

            })
            .catch(error => {
                console.error('There was an error!', error);
                this.setState({ loading: false });

            })
            .finally(() => {
                this.setState({
                    loading_search: false,
                    loading: false
                });
            });
    }



    handleDeleteQuestion = (questionToDelete) => {
        fetch(`${URL}api/conteudo/delete_question/${questionToDelete}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    this.setState({ errorMsg: data.error, showDeleteConfirmation: false });
                } else {
                    this.setState({ showDeleteConfirmation: false });
                    const { id } = this.props.match.params;
                    this.getQuestions(id);
                }
            })
            .catch(error => {
                console.error(error);
                this.setState({ redirect: true });
            });
    };

    render() {
        const { loading, name, type, file, created_at, thumbnail, has_questions, questoesAll, showDeleteConfirmation, questionToDelete, alternativasPorQuestao, currentQuestion } = this.state;
        const id = this.props.match.params.id

        if (this.state.loading) return <SkeletonCoursePage />
        else return (
            <div className="row">

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">
                            <div style={{ float: 'right' }}>
                                <DefaultButton
                                    bg="secondary"
                                    text="Voltar"
                                    icon={<RiArrowGoBackLine />}
                                    to={`/conteudos`}
                                />
                            </div>
                            <div className="flex jcsb">
                                <h4 className="header">{name}</h4>

                            </div>
                            <div className="w100 margin-bottom">

                                <TextReadMore title="Tipo: " element="div" limit={1000} style={{ width: "100%", marginBottom: 10, whiteSpace: 'pre' }}>
                                    {type}
                                </TextReadMore>
                                <TextReadMore title="Criado em: " element="div" limit={1000} style={{ width: "100%", marginBottom: 10, whiteSpace: 'pre' }}>
                                    {moment(created_at).format('DD/MM/YYYY HH:mm')}
                                </TextReadMore>
                            </div>

                            <Tabs
                                value={this.state.activeTab}
                                onChange={(_, value) => {
                                    this.setState({ activeTab: value });
                                    this.props.history.push({ search: `?tab=${value}` });
                                }}
                                style={{ backgroundColor: "#ececf1" }}
                                indicatorColor="primary"
                                textColor="primary"
                                variant={"scrollable"}
                                scrollButtons={"on"}

                            >
                                <Tab label="Conteúdo" />
                                <Tab label="Questões" />

                            </Tabs>
                            <TabPanel value={this.state.activeTab} index={0}>




                                <br />
                                {!file && (<div className="alert alert-warning" role="alert">
                                    Não foi possível encontrar o arquivo.
                                </div>
                                )}
                                {file && (
                                    <div className="w100 flex align-center fdcolumn margin-bottom">
                                        <div style={{ height: '400px' }}>
                                            {type == 'pdf' ? (
                                                <iframe
                                                    src={`${file}`}
                                                    title="PDF Viewer"
                                                    width="100%"
                                                    height="100%"
                                                    style={{ border: 'none' }}
                                                />
                                            ) : (
                                                <video
                                                    controls
                                                    preload="metadata"
                                                    poster={`${thumbnail}`}
                                                    width="100%"
                                                    height="100%"
                                                    style={{
                                                        border: 'none',
                                                        objectFit: 'cover',
                                                    }}>
                                                    <source src={`${file}`} type="video/mp4" />
                                                    Seu navegador não suporta o elemento de vídeo.
                                                </video>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </TabPanel>
                            <TabPanel value={this.state.activeTab} index={1}>

                                {(
                                    <div className="row">
                                        <div className="col-12">






                                            <p style={{ fontSize: '1rem', color: 'black' }}>Aqui são listados todas as questões do conteúdo {name}</p>

                                            <div className="row">
                                                <div className="col-12">

                                                    {loading ? (
                                                        <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                                                            <CircularProgress />
                                                        </div>
                                                    ) : (
                                                        <div>

                                                            <div className="flex jcsb flex-wrap mb-4">

                                                                <Search placeholder="Pesquisar pelo enunciado da questão" ValueChange={value => {
                                                                    clearTimeout(this.pesquisarTimeout);
                                                                    this.pesquisarTimeout = setTimeout(() => {
                                                                        let page = { ...this.state.pagination };
                                                                        page.current_page = 1;
                                                                        this.setState({ pagination: page, search: value, loading_search: true });
                                                                        this.getQuestions(id, 1, value);
                                                                    }, 500);
                                                                }} />

                                                                <div className=" mt-2" style={{
                                                                    flexBasis: '50%',
                                                                    height: 'max-content',
                                                                    justifyContent: 'flex-end'
                                                                }}>
                                                                    <div className="d-flex " style={{
                                                                        justifyContent: 'flex-end'
                                                                    }}>
                                                                        <Link to={"/conteudo/questao/register/" + this.props.match.params.id}
                                                                            style={{ textDecoration: "none" }}>
                                                                            <Button variant="success" className="flex align-center jcc">
                                                                                <FaListAlt style={{ marginRight: 10 }} />
                                                                                <p style={{ margin: 0 }}>Adicionar nova questão</p>
                                                                            </Button>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="table-rep-plugin">
                                                                <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                                    <table id="tech-companies-1" className="table table-striped">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Nome</th>
                                                                                <th>Enunciado</th>
                                                                                <th>Alternativa Correta</th>
                                                                                <th>Ações</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {this.state.loading_search === false && questoesAll.map((questao, id) => (


                                                                                <tr key={id}>
                                                                                    <td>{questao.nome}</td>
                                                                                    <td>{questao.enunciado.replace(/<[^>]*>?/gm, '')}</td>
                                                                                    <td>{questao.correta})</td>
                                                                                    <td>
                                                                                        <span
                                                                                            className="inline-flex align-center jcc bg-warning icon"
                                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px" }}
                                                                                            title={"Gerenciar"}
                                                                                            onClick={() => this.openModal(questao)}
                                                                                        >
                                                                                            <BsFillEyeFill size={17} color="white" />
                                                                                        </span>

                                                                                        <Link to={`/conteudo/questao/edit/${questao.id}`} onClick={(e) => e.stopPropagation()}>
                                                                                            <span className="inline-flex align-center jcc bg-info icon" style={{ width: "2em", height: "2em", borderRadius: "7px", marginRight: "2px" }} data-toggle="tooltip" title="Editar conteúdo">
                                                                                                <MdModeEdit color="white" size={17} />
                                                                                            </span>
                                                                                        </Link>
                                                                                        <span
                                                                                            onClick={() => this.setState({ showDeleteConfirmation: true, questionToDelete: questao.id })}
                                                                                            className="inline-flex align-center jcc bg-danger icon"
                                                                                            style={{ width: "2em", height: "2em", borderRadius: "7px" }}
                                                                                            title="Deletar treinamento"
                                                                                        >
                                                                                            <MdDelete size={17} color="white" />
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            </div>

                                                            {this.state.loading_search == true &&

                                                                <div className="row" style={{ margin: 0 }}> <div className="col-12 d-flex justify-content-center align-items-center">
                                                                    <CircularProgress />
                                                                </div></div>

                                                            }
                                                            {this.state.loading_search == false && questoesAll.length == 0 && <div className="row w-100">
                                                                <div className="col-12">
                                                                    <p style={{ textAlign: 'center' }}>Nenhuma questão cadastrada</p>

                                                                </div>
                                                            </div>}


                                                            <Pagination
                                                                className={(this.state.pagination.last_page == 1 ? "hide" : "")}
                                                                style={{ display: "flex", justifyContent: "flex-end" }}
                                                                count={this.state.pagination.last_page}
                                                                value={this.state.pagination.current_page}
                                                                onChange={(_, value) => {
                                                                    let page = { ...this.state.pagination };
                                                                    page.current_page = value;
                                                                    this.setState({ pagination: page });
                                                                    this.getQuestions(id, value, this.state.search);
                                                                }}
                                                            />

                                                            <Modal show={this.state.showModal} onHide={this.closeModal}>
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>Questão de: {currentQuestion?.nome}</Modal.Title>

                                                                </Modal.Header>

                                                                <Modal.Body>
                                                                    <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: currentQuestion?.enunciado }}>

                                                                    </div>
                                                                    <hr />

                                                                    <p style={{ color: 'black' }}><b>Alternativas:</b></p>

                                                                    {currentQuestion?.alternativas?.map((alternativa, index) => (
                                                                        <div key={index} className={alternativa.alternativa_correta ? 'text-success' : ''} style={{ display: 'flex', marginTop: 10 }}>
                                                                            <div style={{ width: '10%' }}>
                                                                                <p>{String.fromCharCode(97 + alternativa.order)})</p>
                                                                            </div>

                                                                            <div style={{ width: '90%' }}>
                                                                                <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: alternativa.name }}>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}

                                                                </Modal.Body>
                                                            </Modal>

                                                            <SweetAlert
                                                                warning
                                                                title={"Atenção"}
                                                                show={showDeleteConfirmation}
                                                                onConfirm={() => this.handleDeleteQuestion(questionToDelete)}
                                                                onCancel={() => this.setState({ showDeleteConfirmation: false })}
                                                                confirmBtnText='Sim, desejo apagar'
                                                                cancelBtnText='Cancelar'
                                                                confirmBtnBsStyle="success"
                                                                cancelBtnBsStyle="danger"
                                                                showCancel={true}
                                                            >
                                                                Ao deletar esta questão, ela não estará mais disponível no sistema
                                                            </SweetAlert>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )}
                            </TabPanel>

                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const mapsStateToProps = (state) => ({
    user: state.AppReducer.user,
    token: state.AppReducer.token,
});

const SkeletonCoursePage = () => {
    return (
        <div className="card">
            <div className="card-body">
                <div className="skeleton-title" />
                <br />
                <div className="w100 flex jcc align-center">
                    <div className="skeleton-base skeleton-shimmer" style={{ width: "12em", height: "12em" }} />
                </div>
                <br />
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{ minHeight: "48px" }} />
                <div className="w100 skeleton-base skeleton-shimmer margin-bottom" style={{ minHeight: "5em" }} />
                <div className="skeleton-small-button skeleton-shimmer" />
            </div>
        </div>
    );
}

export default connect(mapsStateToProps, { logout })(ShowConteudo);
