




import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { logout } from '../../actions/AppActions';
import { connect } from 'react-redux';

// users
import moment from 'moment';
import { API_URL, STORAGE_URL, URL } from '../../variables';
import { maskNumber } from '../../Auxiliar/Masks';

import ReactPaginate from 'react-paginate';
import { MdReply } from 'react-icons/md';
import { Form, FormControl as Input, InputGroup, Button, Select, Spinner } from "react-bootstrap";
import ImageLoaderInput from '../ReusableComponents/ImageLoaderInput';
import Attention from '../ReusableComponents/Attention';
import { RiArrowGoBackLine } from 'react-icons/ri';
import DefaultButton from '../ReusableComponents/DefaultButton';
import JoditEditor from 'jodit-react';
import { createGlobalStyle } from 'styled-components';

const StyleQuestion = createGlobalStyle`
 p {
letter-spacing: 0.2px;
margin-bottom: 3px;
 }
`

class EditarFaculdade extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            path: '/',
            loading_screen: true,
            roles: [],
            name: '',
            caminho: '',
            termos: '',
            texto_login:'',
            loading_save: false,
            msgErro: '',
            loading_image: false,
            logo_path:'',
            criptografias:[
                {id:'bcrypt','name':'bcrypt'},
                {id:'md5','name':'md5'},
            ],
            logo:''
        };

        this.config = {
            height: 300,
            // askBeforePasteHTML:false,
            // askBeforePasteFromWord:false,
            // defaultActionOnPaste:'insert_only_text',
            // defaultActionOnPasteFromWord:'insert_only_text',
            // pasteHTMLActionList:[
            //     { value: 'insert_only_text', text: 'Insert only Text' }
            // ],
            // pasteFromWordActionList:[
            //     { value: 'insert_only_text', text: 'Insert only Text' }
            // ],
            readonly: this.state.loading_image,
            language: 'pt_br',
            toolbarButtonSize: 'large',
            "buttons": "source,bold,italic,underline,strikethrough,eraser,superscript,subscript,ul,ol,indent,outdent,left,center,right,justify,font,fontsize,paragraph,classSpan,image,brush,,undo,redo,hr,link,symbol,table",
            uploader: {
                url: API_URL + 'faculdade/upload_image',  //your upload api url
                insertImageAsBase64URI: false, //not inster base64
                imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
                headers: { "accept": `application/json`, 'Authorization': 'Bearer ' + localStorage.getItem('token') },
                filesVariableName: function (t) {
                    return 'imagem';
                }, //"files",
                withCredentials: false,
                pathVariableName: 'path',
                //   format: 'json',
                method: 'POST',
                prepareData: (formdata) => {
                    console.log(formdata)
                    this.setState({ loading_image: true });
                    return formdata;
                },
                isSuccess: (e) => {
                    console.log(e);

                    return 'Upload realizado com sucesso';
                },
                getMessage: (e) => {
                    console.log(e);

                    return e;
                },
                process: (resp) => { //success callback transfrom data to defaultHandlerSuccess use.it's up to you.
                    let files = [];
                    console.log(resp);
                    files.unshift(STORAGE_URL + resp.imagem);
                    this.setState({ loading_image: false });

                    return {
                        files: files,

                    };
                },
                error: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message, 'error', 4000);

                    console.log(e);
                    console.log(elem);
                    console.log('Bearer ' + localStorage.getItem('token'));
                },
                defaultHandlerSuccess: function (resp, elem) { // `this` is the editor.
                    if (resp.files && resp.files.length) {
                        resp.files.forEach((filename, index) => { //edetor insertimg function
                            this.selection.insertImage(filename);
                        });
                    }

                    // debugger;

                },
                defaultHandlerError: (elem, e) => {
                    // elem.j.e.fire('errorMessage', e.message);
                    console.log(e);
                    console.log(elem);
                    this.setState({ loading_image: false });

                },
                //   contentType: function (e) {
                //     return (

                //       'application/x-www-form-urlencoded; charset=UTF-8'
                //     );
                //   },
            },
        }
    }
    componentWillUnmount(){
        Array.from(document.getElementsByClassName('jodit-wysiwyg')).forEach((el) => {
            // Do stuff here
            el.blur()
        })
    }

    get_faculdade(id) {

        if (this.state.loading_screen == false) {
            this.setState({ loading_screen: true });
        }
        fetch(`${URL}api/faculdade/get_faculdade/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');localStorage.removeItem('user');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ redirect: true });
    
                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ loading_screen: false, termos: resp.faculdade.termos, 
                        name: resp.faculdade.name,
                        caminho: resp.faculdade.slug,
                        logo_path:resp.faculdade.logo,
                        criptografia:resp.faculdade.criptografia,
                        texto_login:resp.faculdade.texto_login

                    });
                    // if (resp.processo != null) {
    
    
                }
    
    
    
    
            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);
    
    
            }
    
        })
            .catch((err) => {
                this.setState({ redirect: true });
    
                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }



    salvar(e) {
        e.preventDefault();
        this.setState({ loading_save: true, msgErro: '' });
        let form=new FormData();
        form.append('name',this.state.name);
        form.append('logo',this.state.logo);
        form.append('termos',this.state.termos);
        form.append('caminho',this.state.caminho);
        form.append('texto_login',this.state.texto_login);

        form.append('faculdade_id',this.props.match.params.id);


        fetch(`${URL}api/faculdade/update_faculdade`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token'); localStorage.removeItem('user');
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    window.scrollTo(500, 0);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    componentDidMount() {
        this.get_faculdade(this.props.match.params.id);
    }





    change_tipo(event) {
        this.setState({ tipo: event.target.value });
    }


    render() {
        return (
            <div className="row">
                {this.state.redirect == true && <Redirect to={this.state.path} />}

                <div className="col-12">
                    <div className="card">
                        <div className="card-body" id="card">


                            <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>Editar {(this.props.user.empresa==null || this.props.user.empresa.change_text==false)?'Instituição':'Processo'}</h4>
                            <p style={{ fontSize: '1rem', color: 'black' }}>Edite {(this.props.user.empresa==null || this.props.user.empresa.change_text==false)?'uma instituição':'um processo'} do seu sistema</p>
                            <br />

                            {/* <hr /> */}
                            {this.state.loading_screen == false && <div>
                                {this.state.msgErro != '' && <div style={{display:'flex',marginBottom:15,width:'100%'}}>
                                    <div className="alert alert-danger alert-dismissible fade show mb-0 w-100" role="alert">
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => this.setState({ msgErro: '' })}></button>


                                        <p style={{ marginBottom: 0}}>{this.state.msgErro}</p>

                                    </div>
                                    <br />

                                </div>}
                                <StyleQuestion/>
                                <Form.Label>Logo {(this.props.user.empresa==null || this.props.user.empresa.change_text==false)?'da instituição':'do processo'} do seu sistema <Attention /> </Form.Label>
                                <ImageLoaderInput path={this.state.logo_path} width_='100%' height_='200px' ImageChange={image => this.setState({ logo: image })} />
                                <Form onSubmit={(e)=>this.salvar(e)} style={{ position: "relative" }}>
                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Nome <Attention /></Form.Label>
                                            <Input
                                                placeholder={"Nome "+((this.props.user.empresa==null || this.props.user.empresa.change_text==false)?'da instituição':'do processo')}
                                                value={this.state.name}
                                                onChange={(e) => this.setState({ name: e.target.value,caminho:encodeURIComponent(e.target.value.toLowerCase().replace(/[^\w\s]/gi, '-')).replace(/%20/g,'-') })}
                                                required
                                            // title="Coloque o nome completo do usuário"
                                            />
                                        </div>
                                    </InputGroup>
                                    {this.props.user.empresa.online_proccess==true && <>
                                    <InputGroup>
                                    <div className="flex fdcolumn flex1 margin-bottom">
                                        <Form.Label>Texto informativo na tela de login </Form.Label>
                                        <Input
                                           placeholder="Texto informativo para os candidatos que irá aparecer na tela de login."
                                            value={this.state.texto_login}
                                            onChange={(e) => this.setState({ texto_login: e.target.value })}
                                            required
                                        // title="Coloque o nome completo do usuário"
                                        />
                                    </div>
                                    </InputGroup>
                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Caminho da URL <Attention /></Form.Label>
                                            <Input
                                                placeholder={"Caminho da url "+((this.props.user.empresa==null || this.props.user.empresa.change_text==false)?'da instituição':'do processo')}
                                                value={this.state.caminho}
                                                disabled
                                                onChange={(e) => this.setState({ caminho:encodeURIComponent(e.target.value.toLowerCase().replace(/[^\w\s]/gi, '-')).replace(/%20/g,'-') })}
                                                required
                                            // title="Coloque o nome completo do usuário"
                                            />
                                        </div>
                                    </InputGroup>
                                    {/* <InputGroup>
                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label>Tipo de criptográfia de senhas <Attention /></Form.Label>
                                            <select readOnly disabled className="form-select "  value={this.state.criptografia}>
                                                <option value="">Selecione um criptográfia</option>
                                                {(this.state.criptografias && this.state.criptografias.length != 0) && this.state.criptografias.map((value, index) => (
                                                    <option
                                                        key={index}
                                                        value={value["id"]}
                                                    >{value["name"]}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </InputGroup> */}
                                    <InputGroup>

                                        <div className="flex fdcolumn flex1 margin-bottom">
                                            <Form.Label >Termos de uso {(this.props.user.empresa==null || this.props.user.empresa.change_text==false)?'da instituição':'do processo'} <Attention /> {this.state.loading_image==true && <div className="spinner-border text-primary spinner-border-sm" role="status"  >
                                        </div>}</Form.Label>
                                            <JoditEditor
                                                ref={this.editor}
                                                value={this.state.termos}
                                                config={this.config}
                                                height={400}
                                                tabIndex={1} // tabIndex of textarea
                                                onBlur={newContent => this.setState({ termos: newContent })} // preferred to use only this option to update the content for performance reasons
                                            // onChange={newContent => {this.setState({enunciado:newContent}) }}
                                            />
                                        </div>
                                    </InputGroup>
                                    </>}

                                    {this.state.loading_save == false && <div className="w100 inline-flex jcsb margin-top-1">
                                        {/* <Link to={`/accounts/list`}>
                                    <Button variant="secondary"><RiArrowGoBackLine/> Voltar</Button>
                                </Link> */}
                                        <DefaultButton
                                            bg="secondary"
                                            text="Voltar"
                                            icon={<RiArrowGoBackLine />}
                                            to="/"
                                        />
                                        {/* <Button variant="success" type="submit" disabled={this.loading}>
                                    {this.state.loading ? <Spinner /> : ""}
                                    Registrar usuário
                                </Button> */}
                                        <DefaultButton
                                            type="submit"
                                            bg="confirm"
                                            text={"Salvar "+((this.props.user.empresa==null || this.props.user.empresa.change_text==false)?'instituição':'processo')}
                                            disabled={this.state.loading_image==true}
                                            loadingtext={"Salvando "+((this.props.user.empresa==null || this.props.user.empresa.change_text==false)?'da instituição':'do processo')}
                                            loading={this.state.loading_save}
                                        />
                                    </div>}

                                    {this.state.loading_save == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center margin-top-1" >
                                        <div className="spinner-border text-primary" role="status"  >
                                        </div>
                                    </div></div>}
                                </Form>

                            </div>}

                            {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                                <div className="spinner-border text-primary" role="status"  >
                                </div>
                            </div></div>}


                        </div>

                    </div>
                </div>
            </div>
        );
    }


}
const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        user: state.AppReducer.user,

    }
);

export default connect(mapsStateToProps, { logout })(EditarFaculdade);


