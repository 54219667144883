export const handleFileChange = (
  newFile,
  setPreview,
  setNewFile,
) => {
  const reader = new FileReader();
  reader.onloadend = () => {
    setPreview(reader.result);
  };
  reader.readAsDataURL(newFile);
  setNewFile(newFile);
};
